import React, { PureComponent } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

const WorkIcon = () => {

    return (
        <div className="spor-timeline__icon">

        </div>
    );
};
const StartIcon = () => {

    return (
        <div className="spor-timeline__icon">

        </div>
    );
};

const TimelineItem = ({ title, location, description }) => {
    return (
        <div className="spor-timeline__item">
            <h3 className="vertical-timeline-element-title">{title}</h3>
            <h4 className="vertical-timeline-element-subtitle">{location}</h4>
            <p>
                {description}
            </p>
        </div>
    );
};

class Brand extends PureComponent {

    render() {
        const events = [
            {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            },
            {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }, {
                className: 'vertical-timeline-element--work',
                date: '21-01-2018',
                iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
                icon: WorkIcon,
                info: {
                    title: 'Reunião 1',
                    location: 'Lisboa, PT',
                    description: 'Venha a lisboa.',
                }
            }
        ];
        const iconStyle1 = { background: 'rgb(233, 30, 99)', color: '#fff' };
        const iconStyle2 = { background: 'rgb(33, 150, 243)', color: '#fff' };

        return (
            <div className="spor-timeline">
                <h2>Timeline</h2>
                <VerticalTimeline>
                    {
                        events.map((obj, index) => {
                            const iconStyle = index % 2 == 0 ? iconStyle2 : iconStyle1;
                            return (
                                <VerticalTimelineElement
                                    key={index}
                                    className={obj.className}
                                    date={obj.date}
                                    iconStyle={iconStyle}
                                    icon={<WorkIcon/>}>

                                    <TimelineItem {...obj.info}/>

                                </VerticalTimelineElement>
                            );
                        })
                    }
                    <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                        icon={<StartIcon/>}/>
                </VerticalTimeline>
            </div>
        );
    }
}

export default Brand;
