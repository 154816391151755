import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ImageUploader, Text } from './../inputs/index'
import * as Validators from './../utils/validators'
import { CRUDService } from './../../../services'
import EntityCategory from './../../components/entities/category'
import InputError from "../../../common/components/InputError";

export const required = value => (value ? undefined : 'Required');

const Products = ({ fields, metadata, meta }) => {

    return (<div className="row">
        {
            fields.map((field, index) => {
                const prod = fields.get(index);
                return (
                    <div key={index} className="col-md-2">
                        <EntityCategory {...prod}/>
                    </div>
                );
            })
        }
        <InputError {...meta} metadata={metadata}/>
    </div>);
};

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values = {}) {
        const { entity, dispatch } = this.props;
        const successHandler = this.props.successHandler;
        const action = values.id > 0 ? CRUDService.editEntity : CRUDService.createEntity;

        const newValues = { ...values };
        delete newValues.categories;

        return dispatch(action(entity, newValues))
            .then((res) => {
                this.setState({
                    isLoading: false
                });
                successHandler();
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    render() {
        const { dispatch, handleSubmit, pristine, reset, submitting, close } = this.props;

        return (
            <form className="ff-admin-form" onSubmit={handleSubmit(this.handlerSubmit.bind(this))}>
                <div className="ff-admin-form__header">
                    <h2>Categoria</h2>
                </div>
                <div className="ff-admin-form__content">
                    <Tabs>
                        <TabList>
                            <Tab>Categoria</Tab>
                            <Tab>Categorias</Tab>
                        </TabList>
                        <TabPanel>
                            <div className="row">
                                <div className="col-md-4">
                                    <Field
                                        label="Codigo"
                                        name="code"
                                        validate={[Validators.required]}
                                        component={Text}/>

                                    <Field
                                        label="Nome"
                                        name="name"
                                        validate={[Validators.required]}
                                        component={Text}/>
                                    <Field
                                        label="Descrição"
                                        name="description"
                                        component={Text}/>
                                </div>
                                <div className="col-md-4">
                                    <Field
                                        isClear={true}
                                        onlyUrl={true}
                                        label="Imagem principal"
                                        name="image"
                                        component={ImageUploader}/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <h4>Categorias</h4>

                            <FieldArray
                                name="categories"
                                component={Products}/>

                        </TabPanel>
                    </Tabs>
                </div>

                <div className="ff-admin-form__footer">
                    <hr/>
                    <div className="ff-admin-form__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xl"
                            onClick={close}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={pristine || submitting}
                            className="btn btn-primary btn-xl"
                            onClick={reset}>Limpar
                        </button>
                    </div>
                    <div className="ff-admin-form__footer-actions pull-right">
                        <button
                            type="submit"
                            disabled={pristine || submitting}
                            className="btn  btn-primary btn-xl">Gravar
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Page;