import React from "react";
import { connect } from 'react-redux'
import Popover from "../Popover";
import { Link } from 'react-router-dom';

class Main extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { user, isAuthenticated } = this.props;

        return (
            <Popover disabled={!isAuthenticated}>
                <div className="ff-my-account__label">a minha conta</div>
                {
                    isAuthenticated ?
                        <div className="ff-cart">
                            <h4>{user.name}</h4>
                            <div className="ff-my-account__actions">
                                <Link to='/my-account'>A minha informação</Link>
                            </div>
                        </div>
                        :
                        <div></div>
                }
            </Popover>
        )
    }
}

Main.defaultProps = {};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth.user
    }
};

export default connect(mapStateToProps)(Main);
