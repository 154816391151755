import React from 'react'
import BEMHelper from 'react-bem-helper';
import uploadImages from './api';
import InputError from "../../../../common/components/InputError";
import Dropzone from "react-dropzone";

const bem = new BEMHelper({
    prefix: 'ff-admin-input-',
    name: 'avatar'
});

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = { onDrag: false, galleryModalIsOpen: false };
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.clear = this.clear.bind(this);
        this.remove = this.remove.bind(this);
        this.addImages = this.addImages.bind(this);
    }

    remove(ev) {
        ev.stopPropagation();
        this.props.remove();
    };

    clear(ev) {
        ev.stopPropagation();
        const { input: { onChange } } = this.props;
        onChange('');
    };

    addImages(images) {
        const { add, multiFields = false, onlyUrl = false, input: { onChange } } = this.props;

        const action = multiFields ? add : onChange;

        images.map((imageData) => {
            if (onlyUrl) {
                action(imageData.image);
            } else {
                action(imageData);
            }
        });
    }

    onDragOver() {
        this.setState({ onDrag: true });
    };

    onDragLeave() {
        this.setState({ onDrag: false });
    };

    onDrop(acceptedFiles) {
        const { multiFields = false, dispatch } = this.props;

        this.setState({
            isLoading: true
        });

        const addImages = this.addImages;
        uploadImages(dispatch, multiFields, acceptedFiles, (res) => {
            this.setState({
                isLoading: false
            });
            addImages(res);
        });
    };

    getImageInformation(value) {
        const info = { url: '', hasImage: false };
        if (value !== '') {

            if (typeof value === 'string') {
                info.url = value;
                info.hasImage = true;
            } else {
                info.url = value.image;
                info.hasImage = true;
            }

        }

        return info;
    }

    render() {
        const { label, input: { value, onChange }, meta, metadata } = this.props;
        const { isLoading, onDrag } = this.state;
        const imageInfo = this.getImageInformation(value);
        return (
            <div>
                <div className="dummy"></div>
                <div className="dummy-in">
                    <div {...bem('container', { drag: onDrag })}>
                        <Dropzone
                            ref="dropzoneRef"
                            disableClick={true}
                            {...bem('drop-here')}
                            onDragOver={this.onDragOver}
                            onDragLeave={this.onDragLeave}
                            onDrop={this.onDrop}>


                            {
                                imageInfo.hasImage &&
                                <div {...bem('img-container')}>
                                    <img {...bem('img')} src={`/images/gallery/thumbnails/${imageInfo.url}`}/>
                                    <div {...bem('img-container-hover')}>
                                        <button className="btn btn-default btn-sm" onClick={() => {
                                            onChange(null);
                                        }
                                        }>Limpar
                                        </button>
                                    </div>
                                </div>
                            }

                            {
                                !imageInfo.hasImage &&
                                <a {...bem('link')}
                                   onClick={() => {
                                       this.refs.dropzoneRef.open();
                                   }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <label {...bem('drop-here-label')}>Arrastar para aqui</label>
                                        <br/>
                                        <label {...bem('drop-here-label')}>ou click aqui</label>
                                    </div>
                                </a>
                            }
                        </Dropzone>
                    </div>
                </div>
            </div>
        );
    }
}

export default Image;
