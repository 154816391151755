import React from 'react';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { AuthService } from '../../../../services';
import Login from './Form';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(credentials) {
        return this.props.dispatch(AuthService.login(credentials))
            .catch((errorData) => {
                throw new SubmissionError({
                    password: errorData.error,
                    _error: 'Login failed!'
                })
            });

    }

    render() {
        const { isAuthenticated, isAdmin, handleSubmit } = this.props;

        console.log("PROPS-->", this.props)
        if (isAuthenticated) {
            //todo andre: Injectar as rotas e colocar por defeito a rota da primeira posição
            const to = '/pages/home';
            return (
                <Redirect to={to}/>
            )
        }

        return (
            <section className="ff-main-section ff-main-section__login">
                <div className="contact">
                    <div className="container">
                        <div className="row">
                            <form className="ff-form_loginForm" onSubmit={handleSubmit(this.handlerSubmit)} noValidate>
                                <Login/>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Page.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Page;
