import React from 'react';

const INITIAL_SATATE = 0;

const StateUserContact = ({ state }) => {
    return (
        <div className="ff-user-contact-state">

        </div>
    );
};

const UserContact = ({ id, name, email, phone, msg, state }) => {

    return (
        <div className="ff-admin-form__content">
            <h3>Quero ser sócio (#{id}): Sr(a). {name}</h3>
            <StateUserContact state={state} id={id}/>

            <div className="row">
                <div className="col-md-12">
                    <label>Email</label>: {email}
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label>Telefone</label>: {phone}
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <label>Carta de motivacao</label>
                </div>

            </div>


            <div className="row">
                <div className="col-md-12">
                    <div className="ff-user-contact__msg">
                        {msg}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserContact;
