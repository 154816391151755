import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Prod = ({ info, is_publicity }) => {
    return (
        <div className="ff-slick__item">
            {
                is_publicity && <div>
                    <div className="ff-slick__item-image-container">
                        <img src={`/images/gallery/originals/${info.product.main_image}`}/>
                    </div>
                    <div className="ff-slick__item-content">
                        <div className="ff-slick__item-info">
                            <h2>{info.product.name}</h2>
                            <span>{info.product.description}</span>
                        </div>
                    </div>
                </div>
            }

            {
                !is_publicity && <Link to={`/products/${info.product.id}`}>
                    <div className="ff-slick__item-image-container">
                        <img src={`/images/gallery/originals/${info.product.main_image}`}/>
                    </div>
                    <div className="ff-slick__item-content">
                        <div className="ff-slick__item-info">
                            <h2>{info.product.name}</h2>
                            <span>{info.product.description}</span>
                            <h4>Preço base {info.product.base_price} €</h4>
                        </div>
                    </div>
                </Link>
            }
        </div>

    )
};

const Cat = ({ info, is_publicity }) => {
    return (
        <div className="ff-slick__item">
            <Link to={`/categories/${info.category.id}`}>
                <div className="ff-slick__item-image-container">
                    <img src={`/images/gallery/originals/${info.category.image}`}/>
                </div>
                <div className="ff-slick__item-content">
                    <div className="ff-slick__item-info">
                        <h2>{info.category.name}</h2>
                        <span>{info.category.description}</span>
                    </div>
                </div>
            </Link>
        </div>

    )
};

class MySlider extends React.Component {
    render() {
        const prods = this.props.prods || [];
        const is_publicity = this.props.is_publicity;

        const settings = {
            className: 'ff-slick',
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <Slider {...settings}>
                {
                    prods.map((info, index) => {
                        return info.type === 'Product' ?
                            <Prod
                                key={index}
                                is_publicity={is_publicity}
                                info={info}
                                index={index}/>
                            :
                            <Cat
                                key={index}
                                is_publicity={is_publicity}
                                info={info}
                                index={index}/>
                    })
                }
            </Slider>
        );
    }
}

export default MySlider;