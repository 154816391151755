export const AUTH_LOGIN = 'AUTH_LOGIN';
export const USER_INFO = 'USER_INFO';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//CAR
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const PAY_PRODUCT_TO_CART = 'PAY_PRODUCT_TO_CART';
export const GET_CART = 'GET_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const REMOVE_BY_INDEX_CART = 'REMOVE_BY_INDEX_CART';

// Orders
const ORDERS = 'ORDERS';
export const GET_ALL_ORDERS = `GET_ALL_${ORDERS}`;
export const FETCH_ORDERS = `FETCH_ORDERS${ORDERS}`;

// Order Detail
const ORDER_DETAIL = 'ORDER_DETAIL';
export const GET_ORDER_DETAIL = `GET_${ORDER_DETAIL}`;

