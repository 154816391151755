import React from 'react'
import BEMHelper from 'react-bem-helper';
import SectionTypeDate from "../PageSections/date";

const bem = new BEMHelper({
    prefix: 'spor-',
    name: 'feed-news'
});

const bemArticle = new BEMHelper({
    prefix: 'spor-',
    name: 'feed-news-by-categories'
});

const Article = ({ image, title, date }) => {
    return (
        <article {...bemArticle('article')}>
            <div {...bemArticle('article-img-container')}>
                <img src={`/images/gallery/originals/${image}`}/>
            </div>
            <div {...bemArticle('article-info')}>
                <h3>{title}</h3>
                <SectionTypeDate date={date}/>
            </div>
        </article>
    );
};

const ArticleSmall = (props) => {

    return (
        <div {...bem('article', 'small', 'col-lg-6')}>
            <Article  {...props}/>
        </div>
    )
};

const ArticleBig = ({ values = [] }) => {
    const list = values.splice(1, 2);

    return (
        <div className="col-lg-6">
            <article {...bem('article', 'big')}>
                <Article  {...values[0]}/>
            </article>
            <div className="row">
                {
                    list.map((values, index) => {
                        return <ArticleSmall key={index} {...values}/>
                    })
                }
            </div>
        </div>
    )
};

const Splice = (values, size) => {
    const arrays = [];

    while (values.length > 0)
        arrays.push(values.splice(0, size));

    return arrays;
};

class Brand extends React.PureComponent {

    render() {
        const { render_type, description, values = [] } = this.props;
        const splited = Splice(values, 3);

        return (
            <section {...bem()}>
                <div {...bem('title')}>
                    <div dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
                <div className="row">
                    {
                        splited.map((vals, index) => {
                            return <ArticleBig key={index} values={vals}/>
                        })
                    }
                </div>
            </section>
        );
    }
}

export default Brand;
