import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServicePage from '../../../services/PageInfo';

const GroupOfPages = (props) => {
    const { select, isSelected, description } = props;
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/1525288786-twix_orig_01.png`}/>
            </div>
            <label>Grupo de paginas: {description}</label>
        </a>
    );
};

const Page = ({ select, isSelected, image, name }) => {
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/1525288786-twix_orig_01.png`}/>
            </div>
            <label>P: {name}</label>
        </a>
    );
};

const Cat = ({ select, isSelected, image, name }) => {
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/${image}`}/>
            </div>
            <label>SC: {name}</label>
        </a>
    );
};

const GROUP_OF_PAGES_TYPE = 'GroupOfPages';
const PAGE_TYPE = 'Page';

class InputEntities extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { matrix: {} };
        this.successHandler = this.successHandler.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.selectSuperCategory = this.selectSuperCategory.bind(this);
        //this.selectCategory = this.selectCategory.bind(this);
    }

    successHandler() {
        const { matrix } = this.state;

        const result = Object.keys(matrix).map((key) => {
            return matrix[key];
        });

        this.props.successHandler(result);
    }

    getData() {
        this.props.getEntities('header-entities');
    }

    componentWillMount() {
        this.getData();
    }

    selectPage(index) {
        const { pageEntities: { values } } = this.props;
        const { matrix } = this.state;
        if (matrix[`${PAGE_TYPE}${index}`]) {
            delete matrix[`${PAGE_TYPE}${index}`];
        } else {
            const data = { ...values.pages[index] };
            data.type = PAGE_TYPE;
            data.entity_id = data.id;
            data.isNotSaved = true;
            matrix[`${PAGE_TYPE}${index}`] = data;
        }

        this.setState({ matrix: { ...matrix } });
    }

    selectSuperCategory(index) {
        const { pageEntities: { values } } = this.props;
        const { matrix } = this.state;
        if (matrix[`${GROUP_OF_PAGES_TYPE}${index}`]) {
            delete matrix[`${GROUP_OF_PAGES_TYPE}${index}`];
        } else {
            const data = { ...values.group_of_pages[index] };
            data.type = GROUP_OF_PAGES_TYPE;
            data.entity_id = data.id;
            data.isNotSaved = true;
            matrix[`${GROUP_OF_PAGES_TYPE}${index}`] = data;
        }
        this.setState({ matrix: { ...matrix } });
    }

    render() {
        const { limit = -1, close, pageEntities: { values, isFetching } } = this.props;
        const { matrix } = this.state;

        if (isFetching) {
            return (<div>Loading</div>);
        }
        return (
            <div className="ff-admin-form">
                <div className="ff-admin-form__header">
                    <h2>Header</h2>
                </div>
                <div className="ff-admin-form__content ff-admin-en">

                    {
                        values.pages.map((prod, index) => {
                            const selected = !matrix[`${PAGE_TYPE}${index}`];
                            return (
                                <Page
                                    isSelected={selected}
                                    key={index}
                                    {...prod}
                                    select={() => {
                                        this.selectPage(index);
                                    }}/>
                            );
                        })
                    }

                    {
                        values.group_of_pages.map((prod, index) => {
                            const selected = !matrix[`${GROUP_OF_PAGES_TYPE}${index}`];
                            return (
                                <GroupOfPages
                                    isSelected={selected}
                                    key={index}
                                    {...prod}
                                    select={() => {
                                        this.selectSuperCategory(index);
                                    }}/>
                            );
                        })
                    }
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                </div>
                <div className="ff-admin-form__footer">
                    <hr/>
                    <div className="ff-admin-form__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xs"
                            onClick={close}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={Object.keys(this.state.matrix).length === 0}
                            className="btn btn-primary btn-xs"
                            onClick={() => {
                                this.setState({ matrix: {} })
                            }}>Limpar
                        </button>
                    </div>
                    <div className="ff-admin-form__footer-actions pull-right">
                        <button
                            onClick={this.successHandler}
                            disabled={Object.keys(this.state.matrix).length === 0}
                            className="btn  btn-primary btn-xs">Gravar
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pageEntities: state.PageEntities
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getEntities: ServicePage.get
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(InputEntities)
