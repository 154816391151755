import React from 'react';
import Base from '../Base';

const ENTITY = 'pages';
export const REDUCER_NAME = ENTITY;

const initialState = { isFetching: true, isSingleObject: false, values: [] };

class Pages extends Base {
    constructor(...props) {
        super(...props);
    }
}

const news = new Pages(ENTITY, initialState, true);

export const reducer = news.reducer.bind(news);
news.getAsync.bind(news);

export const get = () => {
    return news.getAsync(`api/${ENTITY}`);
};