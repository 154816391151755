import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';

class Modal extends PureComponent {

    render() {
        const { isOpen, close, showActions = false, classModal = 'ff-modal', overlayClassName = 'ff-modal__overlay', children } = this.props;
        //ReactModal.setAppElement('.ff');
        return (
            <ReactModal
                className={classModal}
                overlayClassName={overlayClassName}
                contentLabel="da"
                isOpen={isOpen}>
                {
                    showActions && <div className="ff-modal__actions">
                        <button type="button" className="btn btn-sm, btn-default" onClick={close}>Fechar</button>
                    </div>
                }
                {children}
                {
                    showActions && <div className="ff-modal__actions">
                        <button type="button" className="btn btn-sm, btn-default" onClick={close}>Fechar</button>
                    </div>
                }
            </ReactModal>
        );
    }
}

Modal.propTypes = {};
Modal.defaultProps = {};

export default Modal;
