import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServicePage from '../../../services/Page';
import Popular from "../../components/Popular";
import SingleArticle from "../../components/SingleArticle";

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData() {
        this.props.getPage('1');
    }

    componentWillMount() {
        //this.getData();
    }

    render() {
        const { Page: { isFetching, values } } = this.props;

        return (
            <section className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <SingleArticle/>
                    </div>
                    <div className="col-lg-4">
                        <Popular/>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Page: state[ServicePage.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getPage: ServicePage.get
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Article)
