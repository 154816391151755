import React from 'react'
import { Field } from 'redux-form'
import * as Validators from './../utils/validators'
import { CRUDService } from './../../../services'
import { DatePicker, Image, RichText, Text } from "../inputs";

export const required = value => (value ? undefined : 'Required');

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values = {}) {
        const { entity, dispatch } = this.props;
        const successHandler = this.props.successHandler;
        const action = values.id > 0 ? CRUDService.editEntity : CRUDService.createEntity;

        const newValues = { ...values };
        delete newValues.categories;

        return dispatch(action(entity, newValues))
            .then((res) => {
                this.setState({
                    isLoading: false
                });
                successHandler();
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, close } = this.props;

        return (
            <form className="ff-admin-form" onSubmit={handleSubmit(this.handlerSubmit.bind(this))}>
                <div className="ff-admin-form__header">
                    <h2>Noticias e Eventos</h2>
                </div>
                <div className="ff-admin-form__content">
                    <div className="row">
                        <div className="col-md-4">
                            <Field
                                label="Titulo"
                                name="title"
                                validate={[Validators.required]}
                                component={Text}/>
                            <Field
                                label="Local"
                                name="address"
                                validate={[Validators.required]}
                                component={Text}/>
                            <Field
                                label="Data"
                                name="date"
                                validate={[Validators.required]}
                                component={DatePicker}/>
                            <label htmlFor="employed">Mostrar apenas no slider</label>
                            <Field
                                name="show"
                                component="input"
                                type="checkbox"/>
                        </div>

                        <div className="col-md-4">
                            <Field
                                isClear={true}
                                onlyUrl={true}
                                label="Imagem"
                                name="image"
                                component={Image}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Field
                                label="Descrição"
                                name="description"
                                validate={[Validators.required, Validators.maxMediumRichText]}
                                component={RichText}/>
                        </div>
                    </div>
                </div>

                <div className="ff-admin-form__footer">
                    <hr/>
                    <div className="ff-admin-form__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xl"
                            onClick={close}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={pristine || submitting}
                            className="btn btn-primary btn-xl"
                            onClick={reset}>Limpar
                        </button>
                    </div>
                    <div className="ff-admin-form__footer-actions pull-right">
                        <button
                            type="submit"
                            disabled={pristine || submitting}
                            className="btn  btn-primary btn-xl">Gravar
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Page;
