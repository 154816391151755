import React, { Component } from 'react';
import { translate } from 'react-i18next';


class InputError extends Component {

    render() {
        const {
            type = "default", metadata = {}, submitFailed = false, dirty = false,
            touched = false, error = false, warning = false, t
        } = this.props;
        return (
            <div className="ff-input-errors">
                <div className="ff-input-errors__errors">
                    {
                        (submitFailed || dirty || touched) && error && <span>
                        {t(`ff-shop:form.validation.errors.${type}.${error}`, metadata)}
                        </span>
                    }
                </div>
                <div className="ff-input-errors__warnings">
                    {
                        (submitFailed || dirty || touched) && warning && <span>
                        {t(`ff-shop:form.validation.warnings.${type}.${warning}`, metadata)}
                    </span>
                    }
                </div>
            </div>
        );
    }
}

export default translate(['ff-shop'], { wait: true })(InputError);
