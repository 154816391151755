import React from "react";
import MultiGrid from './MultiGrid';
import ProductGrid from './ProductGrid';
import { CalculateGrid } from '../../../Utils/GridUtil';
import BEMHelper from 'react-bem-helper';
import Welcome from "../Welcome";
import { ContactsForm, PartnerForm } from "../../../admin/forms/index";
import {
    CLIENT_TYPE_CONTACTS_FORM,
    CLIENT_TYPE_FEATURED,
    CLIENT_TYPE_FORM, CLIENT_TYPE_GRID,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_NEWS_EVENTS,
    CLIENT_TYPE_PARTNER_FORM,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_SLIDER,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_TEXT_IMAGE,
    CLIENT_TYPE_VIDEO
} from "../../../Utils/Consts";
import { Banner } from "../index";
import SectionNews from "./news";
import SectionVideo from "./video";
import SectionGallery from "./gallery";

const bem = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-page-sections'
});

const Image = ({ value, is_banner = false }) => {
    if (is_banner) {
        return <Banner value={value}/>
    } else {
        return (
            <div {...bem('image')}>
                <img src={`/images/gallery/originals/${value}`}/>
            </div>);
    }

};

const Text = ({ value, pdf = null, cols = [] }) => {
    const x = CalculateGrid(cols.length);
    return (
        <div {...bem('text')}>

            {
                value && <div dangerouslySetInnerHTML={{ __html: value }}/>
            }

            {
                cols.length === 0 && pdf && <a target="_blank" href={"/files/" + pdf}>Consulte aqui.</a>
            }

            <div {...bem('text', { single: cols.length === 1 }, 'row')}>
                {
                    cols.map((colData, index) => {
                        return (
                            <div
                                className={`col-md-${x}`}
                                key={index}>
                                <div dangerouslySetInnerHTML={{ __html: colData.value }}/>
                            </div>
                        );
                    })
                }
            </div>
            {
                cols.length !== 0 && pdf && <a target="_blank" href={"/files/" + pdf}>Consulte aqui.</a>
            }
        </div>
    );
};

const Default = ({ typeOfComponent }) => {
    return (<div>
        Default: {typeOfComponent}
    </div>);
};

const Separator = () => {
    return (<div>
        <hr/>
    </div>);
};

const GetInput = (type) => {
    switch (type) {
        case CLIENT_TYPE_TEXT:
            return Text;
            break;
        case CLIENT_TYPE_IMAGE:
            return Image;
            break;
        case CLIENT_TYPE_GRID:
            return SectionGallery;
        case CLIENT_TYPE_SLIDER:
            return MultiGrid;
            break;
        case CLIENT_TYPE_TEXT_IMAGE:
            return Welcome;
            break;
        case CLIENT_TYPE_FEATURED:
            return ProductGrid;
            break;
        case CLIENT_TYPE_SEPARATOR:
            return Separator;
        case CLIENT_TYPE_NEWS_EVENTS:
            return SectionNews;
            break;
        case CLIENT_TYPE_CONTACTS_FORM:
            return ContactsForm;
            break;
        case CLIENT_TYPE_PARTNER_FORM:
            return PartnerForm;
            break;
        case CLIENT_TYPE_VIDEO:
            return SectionVideo;
            break;
        default:
            return Default;
    }
};

const PageSections = ({ showComponentInfo = false, sections = [] }) => {

    if (sections.length === 0) {
        return <NoPageSections/>
    }
    return (
        <div {...bem()}>
            {
                sections.map((sectionData, index) => {
                    let Component;
                    if (sectionData.typeOfComponent == CLIENT_TYPE_FORM) {
                        Component = GetInput(sectionData.render_type);
                    } else {
                        Component = GetInput(sectionData.typeOfComponent);
                    }

                    return (
                        <div key={index} {...bem('section', null, {
                            'container-fluid': sectionData.is_fullscreen || sectionData.render_type == 'Slider',
                            'no-border': sectionData.is_fullscreen || sectionData.render_type == 'Slider',
                            'container': !sectionData.is_fullscreen && sectionData.render_type != 'Slider'
                        })}>
                            {
                                showComponentInfo &&
                                <span>{sectionData.typeOfComponent} - {sectionData.render_type}</span>
                            }
                            <Component {...sectionData}/>
                        </div>
                    );
                })
            }
        </div>
    );
};

const NoPageSections = () => {

    return (
        <div {...bem()}>
            {
                <h3>Página sem conteudo</h3>
            }
        </div>
    );
};

export default PageSections;
