import React from 'react';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'
import Topbar from '../Topbar'

class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(event) {
        event.preventDefault();
        this.props.dispatch(actions.authLogout());
    }

    componentWillMount() {
        this.setState({ expanded: false })
    }

    expand() {
        this.setState({ expanded: !this.state.expanded });
    }

    min() {
        this.setState({ expanded: false });
    }

    render() {
        const { expanded = false } = this.state;
        const { values = [], isAdmin = false } = this.props;

        return (
            <header className="navbar-fixed-top">
                <Topbar/>
                <div className="main_menu_bg">
                    <div className="container">
                        <div className="row">
                            <div className="container-fluid">
                                <Navbar expanded={expanded} className="navbar">
                                    <Navbar.Header>
                                        <Navbar.Toggle onClick={this.expand.bind(this)}>
                                            Menu <i className="fa fa-bars"></i>
                                        </Navbar.Toggle>
                                    </Navbar.Header>
                                    <div className="logo"></div>
                                    <Navbar.Collapse>
                                        <Nav>
                                            {
                                                values.map((value, index) => {
                                                    try {

                                                        if (value.type === 'Page') {
                                                            return (
                                                                <NavItem
                                                                    key={index}
                                                                    className="text-uppercase"
                                                                    onClick={this.min.bind(this)}
                                                                    href={`#/pages/${value.url_code}`}>{value.name}</NavItem>
                                                            )
                                                        } else if (value.type === 'GroupOfPages') {
                                                            return (
                                                                <NavDropdown
                                                                    key={index}
                                                                    eventKey={`3.${index}`}
                                                                    title={value.name}
                                                                    id="basic-nav-dropdown">
                                                                    {
                                                                        value.group_of_pages[0].pages.map(({ page }, index1) => {

                                                                            return (
                                                                                <MenuItem
                                                                                    key={index1}
                                                                                    onClick={this.min.bind(this)}
                                                                                    href={`#/pages/${page.url_code}`}
                                                                                    eventKey={`3.${index}.${index1}`}>{page.name}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </NavDropdown>
                                                            )
                                                        } else {
                                                            return (
                                                                <NavItem
                                                                    key={index}
                                                                    className="text-uppercase"
                                                                    onClick={this.min.bind(this)}
                                                                    href={`#/pages/${value.id}`}>{value.name}</NavItem>
                                                            )
                                                        }
                                                    } catch (e) {
                                                        console.error(e);
                                                    }
                                                })
                                            }
                                            {
                                                isAdmin && <NavDropdown
                                                    eventKey={`4.1`}
                                                    title={'Admin'}
                                                    id="basic-nav-dropdown">

                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/header-footer`}
                                                        eventKey={`3.1}`}>Header</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/pages`}
                                                        eventKey={`3.1}`}>Paginas</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/news`}
                                                        eventKey={`3.2}`}>Slider</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/jorneys`}
                                                        eventKey={`3.3}`}>Jornadas</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/users`}
                                                        eventKey={`3.4}`}>Utilizadores</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/user-contacts`}
                                                        eventKey={`3.5}`}>Contactos</MenuItem>
                                                    <MenuItem
                                                        onClick={this.min.bind(this)}
                                                        href={`#/admin/partners/0`}
                                                        eventKey={`3.6}`}>Socios</MenuItem>
                                                </NavDropdown>
                                            }
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

NavigationBar.defaultProps = {
    brand: 'brand'
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated
    }
};

export default connect(mapStateToProps)(NavigationBar)
