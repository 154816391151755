import React from 'react';
import FontAwesome from 'react-fontawesome';
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'ff-admin-entities-',
    name: 'product'
});

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.selectHandler(this.props.index);
    }

    render() {
        const { isSelected, description, pages } = this.props;
        return (
            <div onClick={this.click} {...bem(null, { selected: isSelected }, {
                'panel': true,
                'panel-primary': !isSelected,
                'panel-success': isSelected
            })}>

                <div className="panel-heading">
                    {
                        isSelected && <div className="select-icon">
                            <FontAwesome name="check"/>
                        </div>
                    }
                    <div className="panel-heading__container">
                        <h4>{description}</h4>
                    </div>
                </div>

                <div className="panel-body">
                    Paginas: {pages.length}
                </div>
            </div>
        );
    }
}

export default Page;
