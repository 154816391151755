import React, { PureComponent } from 'react';
import CreateEntity from '../create-entity';
import EditEntity from '../edit-entity';
import DeleteEntity from '../delete-entity';
import { deleteEntity, getListEntities } from '../../../services/crudService';
import Filters from "./Filters";

class CRUDEntity extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            filterList: [],
            hasFilterActive: false,
            selectedFilters: [],
            list: [],
            isLoading: true,
            selectedIds: []
        };
        this.refresh = this.refresh.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.getData = this.getData.bind(this);
        this.filterHandler = this.filterHandler.bind(this);
    }

    getData(selectedIds) {
        const { dispatch, entity } = this.props;
        this.setState({
            isLoading: true
        });

        dispatch(getListEntities(entity))
            .then((res) => {
                this.setState({
                    isLoading: false,
                    filterList: [],
                    hasFilterActive: false,
                    selectedFilters: [],
                    list: res,
                    selectedIds: selectedIds || this.state.selectedIds
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            list: [],
            selectedIds: this.state.selectedIds
        });
        this.getData();
    }

    refresh() {
        this.getData([]);
    }

    selectHandler(position) {
        const arr = this.state.selectedIds;
        const index = arr.indexOf(position);

        if (index > -1) {
            arr.splice(index, 1);
            this.setState({
                selectedIds: [...arr]
            });
        } else {
            this.setState({
                selectedIds: [...arr, position]
            });
        }
    }

    filterHandler(prop, filters) {
        const { list } = this.state;
        const result = [];

        list.map((obj) => {
            const propValue = obj[prop];
            if (filters.indexOf(propValue) !== -1) {
                result.push(obj)
            }
        });
        this.setState({
            selectedIds: [],
            selectedFilters: filters,
            hasFilterActive: filters.length > 0,
            filterList: result
        });
    }

    render() {
        const { entity, form, entityView, filters = [], showActions = true } = this.props;
        const { isLoading, list, selectedIds, hasFilterActive, filterList, selectedFilters } = this.state;
        const EntityView = entityView;

        const listR = hasFilterActive ? filterList : list;

        return (
            <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            {
                                showActions &&
                                <div className="actions">
                                    <CreateEntity
                                        entity={entity}
                                        addHandler={this.refresh}
                                        form={form}/>

                                    {!isLoading && filters.length > 0 &&
                                    <Filters filterValues={selectedFilters}
                                             filterHandler={this.filterHandler}
                                             listOfFilters={filters}/>}
                                </div>
                            }
                        </div>
                        <div className="panel-body">

                            <div className="row">
                                {
                                    listR.map((entityObj, index) => {
                                        return (
                                            <div key={index} className="col-xs-6 col-md-3 col-lg-3">
                                                <EntityView
                                                    index={index}
                                                    {...entityObj}
                                                    isSelected={selectedIds.indexOf(index) > -1}
                                                    selectHandler={this.selectHandler}/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CRUDEntity;
