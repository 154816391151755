import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Banner, FeedNews, FeedNews2, FeedNewsByCategories, Popular, Timeline } from '../../components';
import * as ServicePage from '../../../services/Page';
import { Redirect } from "react-router";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData() {
        this.props.getPage('1');
    }

    componentWillMount() {
        //this.getData();
    }

    render() {
        const { Page: { isFetching, values } } = this.props;
        return (
            <div>
                <Banner/>
                <div className="container">
                    <FeedNews/>
                    <FeedNews2/>
                    <div className="row">
                        <div className="col-lg-8">
                            <FeedNewsByCategories/>
                        </div>
                        <div className="col-lg-4">
                            <Popular/>
                        </div>
                    </div>
                    <Timeline/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Page: state[ServicePage.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getPage: ServicePage.get
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Home)
