import React from "react";
import BEMHelper from 'react-bem-helper';
import ReactPlayer from 'react-player'

const bem = new BEMHelper({
    prefix: 'smapor-',
    name: 'cli-video'
});
const SectionVideo = (props) => {

    return (
        <div {...bem()}>
            <div {...bem("text", null, "row")}>
                <div {...bem(null, null, "col-xs-12")}>
                    <div dangerouslySetInnerHTML={{__html: props.text}}/>
                </div>
            </div>
            <div {...bem("video")}>
                <ReactPlayer
                    className='react-player'
                    width={'100%'}
                    height={'100%'}
                    url={props.video_url}
                    playing={props.playing}
                    loop={true}
                    controls={true}/>
            </div>
        </div>
    )

};

export default SectionVideo;
