import { groupBy } from "lodash";

const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return (tmp.textContent || tmp.innerText || "").trim();
};

export const required = (value) => {
    return (value ? undefined : messages.required.key);
};

export const richTextRequired = (value) => {
    value = stripHtml(value);
    return (value.length === 0 ? messages.required.key : undefined);
};

export const fieldArrayRequired = (value) => {
    return (value && value.length > 0 ? undefined : messages.required.key);
};

export const fieldArrayUnique = (values) => {
    const group = groupBy(values || [], 'entity_id');
    let cont = 0;
    Object.keys(group).map((key) => {
        if (group[key].length > 1) {
            cont++;
        }
    });

    const result = cont === 0 ? undefined : messages.uniqueList.key;
    return result;
};

export const maxLength = (max) => (value) => {
    return value && value.length > max ? messages.maxLength.key : undefined;
};
export const maxLengthRichText = (max) => (value) => {
    value = stripHtml(value);
    return value && value.length > max ? messages.maxLength.key : undefined;
};

export const maxLength15 = maxLength(15);
export const maxLength12 = maxLength(12);
export const maxLength150 = maxLength(150);
export const maxLength250 = maxLength(250);
export const maxLength1000 = maxLength(1000);
export const maxMediumText = maxLength(10000);
export const maxMediumRichText = maxLengthRichText(10000);

export const minLength = (min) => (value) => {
    return value && value.length < min ? messages.minLength.key : undefined;
};
export const minLengthRich = (min) => (value) => {
    value = stripHtml(value);
    return value && value.length < min ? messages.minLength.key : undefined;
};

export const minLength2 = minLength(2);

export const minLength12 = minLength(12);

export const minLength5 = minLength(5);

export const minLength1 = minLength(1);

export const minLength6 = minLength(6);

export const minLengthRich50 = minLengthRich(50);

export const number = (value) =>
    value && isNaN(Number(value)) ? messages.typeNumber.key : undefined;

export const minValue = (min) => value =>
    value && value < min ? messages.minValue.key : undefined;

export const minValue18 = minValue(18);

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? messages.emailInvalid.key
        : undefined;

const messages = {
    emailInvalid: {
        key: 'email',
        getMessage: (metaData) => {
            return 'Campo inválido'
        }
    },
    minValue: {
        key: 'minValue',
        getMessage: (metaData) => {
            return 'Campo inválido'
        }
    },
    typeNumber: {
        key: 'typeNumber',
        getMessage: (metaData) => {
            return 'Campo inválido'
        }
    },
    minLength: {
        key: 'min_length',
        getMessage: (metaData) => {
            return 'Campo inválido, insira pelo menos ' + metaData.min;
        }
    },
    maxLength: {
        key: 'max_length',
        getMessage: (metaData) => {
            return 'Campo inválido, não sao permitidos mais que ' + metaData.max + ' caracteres';
        }
    },
    required: {
        key: 'required',
        getMessage: (metaData) => {
            return 'Este campo é obrigatório';
        }
    },
    invalid_credentials: {
        key: 'invalid_credentials',
        getMessage: (metaData) => {
            return 'E-mail ou palavra passe estão errados';
        }
    },
    uniqueList: {
        key: 'unique_list',
        getMessage: (metaData) => {
            return 'E-mail já existe.';
        }
    },
    unique: {
        key: 'unique',
        getMessage: (metaData) => {
            return 'E-mail já existe.';
        }
    },
    password_confirmation: {
        key: 'password_confirmation',
        getMessage: (metaData) => {
            return 'As palavras passe tem que ser iguais';
        }
    }
};

export const getMessage = (error, meta = {}) => {
    const messageObj = messages[error];
    if (messageObj) {
        console.warn('getMessage--> ', messageObj.key, error, meta);
        return messageObj.getMessage(meta);
    }
    console.warn('undefined error', error, meta);
    return "Foi devolvido um erro desconhecido";
};
