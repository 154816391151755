import React from 'react';
import {orderBy} from 'lodash';
import Base from '../Base';
import Http from '../../Http';
import {
    CLIENT_TYPE_FORM,
    CLIENT_TYPE_GRID,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_NEWS_EVENTS,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_TEXT_IMAGE,
    CLIENT_TYPE_VIDEO
} from "../../Utils/Consts";

const ENTITY = 'pages';
export const REDUCER_NAME = "pageDetail";

const initialState = {isFetching: true, isSingleObject: true, values: {}};

class Pages extends Base {
    constructor(...props) {
        super(...props);
    }

    _getAsyncMapToServer(data) {
        //images, sliders, texts
        const prop = 'typeOfComponent';
        const propType = 'type';
        const images = data.images.map((obj) => {
            obj[prop] = CLIENT_TYPE_IMAGE;
            return obj;
        });

        const texts = data.texts.map((obj) => {
            obj[prop] = CLIENT_TYPE_TEXT;
            return obj;
        });
        const separators = data.separators.map((obj) => {
            obj[prop] = CLIENT_TYPE_SEPARATOR;
            return obj;
        });
        const forms = data.forms.map((obj) => {
            obj[prop] = CLIENT_TYPE_FORM;
            return obj;
        });
        const imageTexts = data.image_texts.map((obj) => {
            obj[prop] = CLIENT_TYPE_TEXT_IMAGE;
            return obj;
        });

        const entities = data.entities.map((obj) => {
            obj[prop] = CLIENT_TYPE_NEWS_EVENTS;
            return obj;
        });

        const videos = data.videos.map((obj) => {
            obj[prop] = CLIENT_TYPE_VIDEO;
            return obj;
        });
        const grids = data.grids.map((obj) => {
            obj[prop] = CLIENT_TYPE_GRID;
            return obj;
        });

        data.sections = orderBy([...images, ...grids, ...texts, ...separators, ...imageTexts, ...entities, ...forms, ...videos], ['index'], ['asc']);
        return data;
    }

    getAsync(url, params = {}) {
        return (dispatch) => {
            dispatch(this.fetch());
            new Promise((resolve, reject) => {
                Http.get(url, {params})
                    .then((res) => {
                        dispatch(this.get(this._getAsyncMapToServer(res.data)));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        dispatch(this.error());
                        const statusCode = err.response.status;
                        return reject({statusCode});
                    })
            })
        };
    }
}

const news = new Pages(REDUCER_NAME, initialState, true);

export const reducer = news.reducer.bind(news);
news.getAsync.bind(news);
news.deleteAsync.bind(news);
news.putAsync.bind(news);
news.postAsync.bind(news);

export const get = (id, params = {}) => {
    return news.getAsync(`api/${ENTITY}/${id}`, params);
};

export const getInfo = (params = {}) => {
    return news.getAsync(`api/pages-info`, params);
};

export const del = (id) => {
    return news.deleteAsync(`api/${ENTITY}/${id}`);
};
export const post = (data) => {
    return news.postAsync(`api/${ENTITY}`, data);
};
export const put = (id, data) => {
    return news.putAsync(`api/${ENTITY}/${id}`, data);
};
