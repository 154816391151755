import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';

i18n
    .use(Backend)
    .use(reactI18nextModule)
    .init({
        fallbackLng: 'pt',
        // have a common namespace used around the full app
        ns: ['ff-shop'],
        defaultNS: 'ff-shop',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            defaultTransParent: 'div', // needed for preact
            wait: true
        }
    });

export default i18n;
