import * as ActionTypes from '../action-types';

const initialState = {
    orders: [],
    fetch: true
};

const Orders = (state = initialState, { type, error, orders = [], fetch = true }) => {

    switch (type) {
        case ActionTypes.GET_ALL_ORDERS:
            return getAll(state, orders, error);
        case ActionTypes.FETCH_ORDERS:
            return fetchAction(state, fetch);
        default:
            return state;
    }
};

const getAll = (state, orders, error) => {
    state = Object.assign({}, state, { orders, fetch: false, error });
    return state;
};
const fetchAction = (state, fetch) => {
    state = Object.assign({}, state, { fetch });
    return state;
};

export default Orders;