import Http from '../Http'

export function uploadAvatar(file) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post('/api/users/avatar', file)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}
