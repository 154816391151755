import React from 'react';
import { Field, FieldArray } from "redux-form";
import RenderSections from "./RenderSections";

const FormFooter = ({ first = false, close, pristine, submitting, reset }) => {
    return (
        <div className="ff-admin-form__footer">
            {!first && <hr/>}
            <div className="ff-admin-form__footer-actions pull-left">
                <button
                    type="button"
                    disabled={pristine || submitting}
                    className="btn btn-primary btn-xl"
                    onClick={reset}>Limpar
                </button>
            </div>
            <div className="ff-admin-form__footer-actions pull-right">
                <button
                    type="submit"
                    disabled={pristine || submitting}
                    className="btn  btn-primary btn-xl">Gravar
                </button>
            </div>
        </div>
    );
};

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.addNewSection = this.addNewSection.bind(this);
    }

    getData() {
        const { match: { params: { pageId } } } = this.props;
        this.props.getPage(pageId);
    }

    componentWillMount() {
        this.getData();
    }

    handlerSubmit(values = {}) {
        if (values.id > 0) {
            return this.props.putPage(values.id, values);
        } else {
            return this.props.postPage(values);
        }
    }

    addNewSection(values = {}) {

    }

    render() {
        const { Page: { isFetching, values }, handleSubmit } = this.props;

        if (isFetching) {
            return <div>Loading 1</div>;
        }

        return (
            <section className="ff-admin-section ff-adm-page-detail container">

                <div className="row">
                    <div className="col-xs-12">
                        <form className="ff-admin-form" onSubmit={handleSubmit(this.handlerSubmit.bind(this))}>

                            <FormFooter first={true} {...this.props}/>
                            <div className="ff-admin-form__header">
                                <h2>Página: {values.name}</h2>
                                <div>
                                    <label htmlFor="employed">Apenas disponivel para acessos autenticados: </label>
                                    <Field
                                        name="req_auth"
                                        id="req_auth"
                                        component="input"
                                        type="checkbox"/>
                                </div>
                            </div>
                            <div className="ff-admin-form__content">
                                <FieldArray
                                    pageInfo={values}
                                    name="sections"
                                    component={RenderSections}/>
                            </div>
                            <FormFooter {...this.props}/>
                        </form>
                    </div>
                </div>

            </section>
        );
    }
}

export default Page;
