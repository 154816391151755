import React from 'react';
import UserContact from './show';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasAction: true, success: false, error: false };
        this.getData = this.getData.bind(this);
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    getData() {
        const { match: { params: { partnerId } } } = this.props;
        this.props.getById(partnerId);
    }

    componentWillMount() {
        this.getData();
    }

    accept() {
        const { accept, data: { valuesById } } = this.props;
        const _self = this;

        accept(valuesById.id)
            .then(() => {
                //_self.setState({})
            })
            .catch(err => {
                toast.error("Ocorreu um errro na acção", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    reject() {
        const { reject, data: { valuesById } } = this.props;
        reject(valuesById.id)
            .then(() => {

            })
            .catch(err => {
                toast.error("Ocorreu um errro na acção", {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    render() {
        const { data: { isFetching, valuesById }, match: { params } } = this.props;
        if (isFetching) {
            return <div>Loading</div>;
        }

        return (
            <section className="ff-admin-section ff-adm-page-detail container">
                <div className="row">
                    <div className="col-xs-3">
                        <Link
                            className={`btn btn-small btn-default`}
                            to={`/admin/partners/${params.state}`}>VOLTAR</Link>
                    </div>
                </div>
                <UserContact {...valuesById}/>

                <div className="actions">
                    {
                        valuesById.state !== 2 &&
                        <button onClick={this.accept} className="btn btn-default">Aceitar</button>
                    }

                    {
                        valuesById.state === 2 &&
                        <button onClick={this.reject} className="btn btn-default">Rejeitar</button>
                    }
                </div>
            </section>
        );
    }
}

export default Page;
