import React from 'react';
import Dropzone from 'react-dropzone';
import uploadImages from './api';
import { getListEntities } from '../../../services/crudService';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { list: [], isLoading: true, selectedIds: [] };
        this.getData = this.getData.bind(this);
        this.clean = this.clean.bind(this);
        this.select = this.select.bind(this);
        this.returnSelection = this.returnSelection.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    clean() {
        this.setState({ selectedIds: [] });
    }

    returnSelection() {
        const { list = [], selectedIds = [] } = this.state;
        const result = [];
        selectedIds.map((index) => {
            result.push(list[index]);
        });
        this.props.close(result);
    }

    select(id) {
        const { multiFields } = this.props;
        const selectedIds = [...this.state.selectedIds];
        if (multiFields) {
            const indexOf = selectedIds.indexOf(id);
            if (indexOf === -1) {
                this.setState({ selectedIds: [id, ...selectedIds] });
            } else {
                selectedIds.splice(indexOf, 1);
                this.setState({ selectedIds: [...selectedIds] });
            }
        } else {
            this.setState({ selectedIds: [id] });
        }

    }

    getData() {
        const { dispatch } = this.props;
        this.setState({
            isLoading: true
        });

        dispatch(getListEntities('images'))
            .then((res) => {
                this.setState({
                    isLoading: false,
                    list: res
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            list: [],
        });
        this.getData();
    }

    onDragOver() {
        this.setState({ onDrag: true });
    };

    onDragLeave() {
        this.setState({ onDrag: false });
    };

    onDrop(acceptedFiles) {
        const { dispatch } = this.props;

        this.setState({
            isLoading: true
        });

        const getData = this.getData;
        uploadImages(dispatch, true, acceptedFiles, () => {
            this.setState({
                isLoading: false
            });
            getData();
        });
    };

    render() {
        const { close } = this.props;
        const { list = [], selectedIds = [] } = this.state;

        return (
            <div className="ff-image-uploader">
                <div className="ff-image-uploader__header">
                </div>

                <Dropzone
                    className="ff-image-uploader__content"
                    ref="dropzoneRef1"
                    disableClick={true}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}>
                    {
                        list.map((imgData, index) => {
                            const indexOf = selectedIds.indexOf(index);
                            return (
                                <a className={indexOf === -1 ? '' : 'ff-image-uploader__selected'}
                                   onClick={() => {
                                       this.select(index);
                                   }} key={index}>
                                    <img src={`/images/gallery/thumbnails/${imgData.image}`}/>
                                </a>)
                        })
                    }
                    <a></a>
                    <a></a>
                    <a></a>
                    <a></a>
                    <a></a>
                    <a></a>
                    <a></a>
                    <a></a>
                </Dropzone>

                <div className="ff-image-uploader__footer">
                    <hr/>
                    <div className="ff-image-uploader__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xl"
                            onClick={() => {
                                close()
                            }}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={selectedIds.length === 0}
                            className="btn btn-primary btn-xl"
                            onClick={this.clean}>Limpar
                        </button>
                    </div>
                    <div className="ff-image-uploader__footer-actions pull-right">
                        <button
                            disabled={selectedIds.length === 0}
                            className="btn  btn-primary btn-xl"
                            onClick={this.returnSelection}>Gravar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page;