import React from 'react';
import Base from '../Base';
import Http from '../../Http';

export const REDUCER_NAME = "PageEntities";

const initialState = { isFetching: true, isSingleObject: false, values: {} };

class Pages extends Base {
    constructor(...props) {
        super(...props);
    }

    getAsync(url, params = {}) {
        return (dispatch) => {
            dispatch(this.fetch());
            new Promise((resolve, reject) => {
                Http.get(url, { params })
                    .then((res) => {
                        dispatch(this.get(res.data));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        return reject({ statusCode });
                    })
            })
        };
    }
}

const news = new Pages(REDUCER_NAME, initialState, true);

export const reducer = news.reducer.bind(news);
news.getAsync.bind(news);
news.deleteAsync.bind(news);
news.putAsync.bind(news);
news.postAsync.bind(news);

export const get = (url) => {
    return news.getAsync(`api/${url}`);
};
