import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Page from './Page';

export const NAME = 'SuperCategoryForm';

const Form = reduxForm({
    form: NAME
})(Page);

const mapStateToProps = (state, props) => {
    return {
        enableReinitialize: true,
        initialValues: props.defaultValues
    }
};

export default connect(mapStateToProps)(Form)