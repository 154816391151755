import React from 'react';
import { Field } from "redux-form";
import { RichText } from "../../forms/inputs";
import { CalculateGrid } from "../../../Utils/GridUtil";
import InputError from "../../../common/components/InputError";
import * as Validators from "../../forms/utils/validators";

const RenderTextCols = ({ fields, meta, metadata }) => {
    const newCol = { value: 'Escreva aqui o seu texto' };
    const x = CalculateGrid(fields.length);
    return (
        <div className="ff-adm-text-cols">

            <button
                className="btn btn-xs btn-default"
                type="button"
                onClick={() => fields.push(newCol)}>
                Adicionar nova coluna
            </button>


            <div className="row">
                {
                    fields.map((col, index) => {
                        return (
                            <div
                                key={index}
                                className={`ff-adm-text-cols__col col-xs-12 col-md-${x}`}>

                                <div className="ff-adm-text-cols__title">
                                    <h4>Col #{index + 1}</h4>
                                    <button
                                        className="btn btn-xs btn-default"
                                        type="button"
                                        title="Remove Member"
                                        onClick={() => fields.remove(index)}>
                                        <i className="glyphicon glyphicon-trash"></i>
                                    </button>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 ff-adm-text-cols__input">
                                        <Field
                                            metadata={{ min: 50, max: 1000 }}
                                            validate={[Validators.richTextRequired, Validators.minLengthRich50, Validators.maxMediumRichText]}
                                            name={`${col}.value`}
                                            component={RichText}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

export default RenderTextCols;