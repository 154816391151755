import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import Page from './Page';
import * as ServicePage from '../../../services/Page';

export const NAME = 'PageDetailForm';

const Form = reduxForm({
    form: NAME
})(Page);

const mapStateToProps = (state) => {
    return {
        myValues: getFormValues(NAME)(state) || {},
        enableReinitialize: true,
        initialValues: state[ServicePage.REDUCER_NAME].values,
        Page: state[ServicePage.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getPage: ServicePage.get,
            postPage: ServicePage.post,
            putPage: ServicePage.put,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Form)
