import React from 'react';
import { orderBy } from 'lodash';
import Base from '../Base';

const ENTITY = 'news-and-events';
export const REDUCER_NAME = "NewAndEvent";

const initialState = { isFetching: true, isSingleObject: true, values: {} };

class Pages extends Base {
    constructor(...props) {
        super(...props);
    }
}

const news = new Pages(REDUCER_NAME, initialState, true);

export const reducer = news.reducer.bind(news);
news.getAsync.bind(news);
news.deleteAsync.bind(news);
news.putAsync.bind(news);
news.postAsync.bind(news);

export const get = (id, params = {}) => {
    return news.getAsync(`api/${ENTITY}/${id}`, params);
};

export const del = (id) => {
    return news.deleteAsync(`api/${ENTITY}/${id}`);
};
export const post = (data) => {
    return news.postAsync(`api/${ENTITY}`, data);
};
export const put = (id, data) => {
    return news.putAsync(`api/${ENTITY}/${id}`, data);
};
