import React from 'react';
import SelectBox from 'react-select';
import BEMHelper from 'react-bem-helper';
import InputError from "../../../../common/components/InputError";

const bem = new BEMHelper({
    prefix: 'ff-',
    name: 'select'
});

const Select = ({ myOptions = [], input: { value, onChange }, meta, metadata }) => {

    let myValue = value === '' ? null : value;

    if (myValue) {
        for (let i = 0; i < myOptions.length; i++) {
            if (myOptions[i].value === myValue) {
                myValue = myOptions[i];
                break;
            }
        }
    }

    return (
        <section {...bem()}>
            <label>Selecione</label>
            <SelectBox
                closeText="Fechar"
                clearText="Limpar seleção"
                multiple={false}
                value={myValue}
                options={myOptions}
                onChange={(value) => {
                    onChange(value.value)
                }}>
            </SelectBox>
            <InputError {...meta} metadata={metadata}/>
        </section>
    );
};

export default Select;
