import * as ActionTypes from '../action-types'
import Http from '../../Http'
import axios from 'axios'

const user = {
    id: null,
    name: null,
    email: null,
    avatar: null,
    createdAt: null,
    updatedAt: null
};

const initialState = {
    isAuthenticated: false,
    isAdmin: false,
    isLoading: true,
    isRequest: false,
    user
};

const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.USER_INFO:
            return userInfo(state, payload);
        case ActionTypes.AUTH_LOGIN:
            return authLogin(state, payload);
        case ActionTypes.AUTH_CHECK:
            return checkAuth(state);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        default:
            return state;
    }
};

const authLogin = (state, payload) => {
    const jwtToken = payload.token;
    const user = payload.user;
    const isAdmin = payload.user.is_admin;

    localStorage.setItem('is_admin', isAdmin);
    localStorage.setItem('jwtToken', jwtToken);

    Http.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

    state = Object.assign({}, state, {
        isAuthenticated: true,
        isAdmin: isAdmin,
        isLoading: false,
        user
    });

    return state;
};

const userInfo = (state, payload) => {
    let user = payload || {};

    if (user.partner_info) {
        user = { ...user, ...user.partner_info }
    }

    state = Object.assign({}, state, {
        isAuthenticated: true,
        isLoading: false,
        isAdmin: user.type === "ADMIN",
        isRequest: true,
        user
    });

    return state;
};

const checkAuth = (state) => {
    state = Object.assign({}, state, {
        isAuthenticated: !!localStorage.getItem('jwtToken')
    });
    if (state.isAuthenticated) {
        Http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwtToken')}`;
    }
    return state;
};

const logout = (state) => {
    localStorage.removeItem('jwtToken');
    localStorage.setItem('is_admin', false);
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = '';
    Http.defaults.headers.common['Authorization'] = 'Bearer';

    state = Object.assign({}, state, {
        isAuthenticated: false,
        isAdmin: false,
        isLoading: false,
        isRequest: true,
        user
    });

    window.location.href = '/#';
    return state;
};

export default Auth;
