import React from 'react';
import FontAwesome from 'react-fontawesome';
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'ff-admin-entities-',
    name: 'user'
});

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.selectHandler(this.props.index);
    }

    render() {
        const { isSelected, name, last_name, email, last_access, type } = this.props;
        const type_desc = type === 'ADMIN' ? 'Admin' : 'Sócio';
        return (
            <div onClick={this.click} {...bem(null, { selected: isSelected }, {
                'panel': true,
                'panel-primary': !isSelected,
                'panel-success': isSelected
            })}>

                <div className="panel-heading">
                    {
                        isSelected && <div className="select-icon">
                            <FontAwesome name="check"/>
                        </div>
                    }
                    <div className="panel-heading__container">
                        <h4>{name} {last_name} ({type_desc})</h4>
                    </div>
                </div>

                <div className="panel-body">
                    <div {...bem('container')}>
                        <div>
                            <span>Email:</span><label>{email}</label>
                        </div>
                        {
                            last_access && <div>
                                <span>Ultimo acesso:</span><label>{last_access}</label>
                            </div>
                        }

                        {
                            !last_access && <div>
                                <span>Ultimo acesso:</span><label>N/A</label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Page;
