import { ImageService } from '../../../services/index';

const uploadImages = (dispatch, multiFields, files, callback) => {
    const result = [];

    const upload = (index) => {
        if (index === files.length) {
            callback(result);
        } else {
            const formData = new FormData();
            formData.append('photo', files[index]);

            dispatch(ImageService.uploadImage(formData))
                .then((res) => {
                    result.push(res);
                    if (multiFields) {
                        upload(index + 1);
                    } else {
                        upload(files.length);
                    }
                })
                .catch(() => {
                    if (multiFields) {
                        upload(index + 1);
                    } else {
                        upload(files.length);
                    }
                });
        }
    };
    upload(0);
};

export default uploadImages;