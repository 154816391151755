import React from 'react';
import { Field } from 'redux-form';
import BEMHelper from 'react-bem-helper';
import Image from '../image/index';
import InputError from "../../../../common/components/InputError";

const bem = new BEMHelper({
    prefix: 'ff-admin-input-',
    name: 'list-of-images'
});

class ListOfImage extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
    }

    onChange(value) {
        const { fields } = this.props;
        fields.push(value);
    }

    remove(position) {
        const { fields } = this.props;
        fields.remove(position);
    }

    render() {
        const { label, fields, meta, metadata } = this.props;
        return (
            <div {...bem()}>
                <label>{label}</label>
                <div className="row">
                    {
                        fields.map((fieldObj, index) => {
                            return (
                                <div className="col-xs-4 col-md-4">
                                    <Field
                                        multiFields={true}
                                        add={this.onChange}
                                        remove={() => {
                                            this.remove(index);
                                        }}
                                        name={fieldObj}
                                        component={Image}/>
                                </div>
                            );
                        })
                    }

                    <div className="col-xs-4 col-md-4">
                        <Image
                            multiFields={true}
                            add={this.onChange}
                            input={{ onChange: this.onChange, value: '' }}/>
                    </div>
                </div>
                <InputError {...meta} metadata={metadata}/>
            </div>
        );
    }
}

export default ListOfImage;
