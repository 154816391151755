import React from 'react'
import { Field } from 'redux-form';
import * as Validators from '../../../../admin/forms/utils/validators';
import InputError from "../../../../common/components/InputError";

const MyField = (props) => {
    const { disabled, icon, type = 'text', input, label, meta, metadata } = props;

    return (
        <div className="form-group">
            <div className="input-group">
                <span className="input-group-addon">
                    <i className={icon}></i>
                </span>
                <input disabled={disabled} className="form-control input-sm" {...input} type={type}
                       placeholder={label}/>
            </div>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const Form = ({ isLoading }) => {
    return (
        <div className="ff-form_login container">
            <h3 className="ff-form_authTitle">Recuperar palavra passe.</h3>

            <div className="row ff-form_row-sm-offset-3 ff-form_loginOr">
                <div className="col-xs-12 col-sm-6">
                    <hr className="ff-form_hrOr"/>
                </div>
            </div>

            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    <Field
                        validate={[Validators.required, Validators.minLength6]}
                        label="Palavra passe"
                        icon="fa fa-lock"
                        name='password'
                        disabled={isLoading}
                        disable
                        placeholder='Palavra passe'
                        type='password'
                        component={MyField}/>

                    <Field
                        validate={[Validators.required, Validators.minLength6]}
                        label="Repita a palavra passe"
                        placeholder='Repita a palavra passe'
                        icon="fa fa-lock"
                        disabled={isLoading}
                        name='repeat_password'
                        type='password'
                        component={MyField}/>

                    <button
                        disabled={isLoading}
                        className="btn btn-sm btn-primary btn-block"
                        type="submit">Repor
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Form;
