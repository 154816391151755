import { PDFService } from '../../../../services/index';

const uploadImages = (entity, entityId, dispatch, multiFields, files, callback) => {

    const url = entity === 'pages' ? '/api/pages/' + entityId + '/pdf' : '/api/jorney/' + entityId + '/pdf';
    const result = [];

    const upload = (id = 'NEW', index) => {
        if (index === files.length) {
            callback(result);
        } else {
            const formData = new FormData();
            formData.append('pdf', files[index]);
            formData.append('id', id);

            dispatch(PDFService.uploadPDF(url, formData))
                .then((res) => {
                    result.push(res);
                    if (multiFields) {
                        upload(id, index + 1);
                    } else {
                        upload(id, files.length);
                    }
                })
                .catch(() => {
                    if (multiFields) {
                        upload(id, index + 1);
                    } else {
                        upload(id, files.length);
                    }
                });
        }
    };
    upload(entityId, 0);
};

export default uploadImages;
