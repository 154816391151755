import React from 'react';
import BEMHelper from "react-bem-helper";
import { EntityPartner } from "../../components/entities";

const bem = new BEMHelper({
    prefix: 'smapor-',
    name: 'partners'
});

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.create = this.create.bind(this);
    }

    getData(state) {
        this.props.getAllMessages(0, { state });
    }

    create(data) {

    }

    componentWillMount() {
        const { match: { params } } = this.props;
        this.getData(params.state);
    }

    render() {
        const { data: { isFetching, values = [] }, match: { params } } = this.props;
        const hasValues = values.length !== 0;

        return (
            <section className="ff-admin-section ff-adm-page-detail container">
                <div  {...bem()}>
                    <h3>Quero ser socio</h3>

                    <div {...bem('actions')}>

                        <a
                            href={`#/admin/partners/0`}
                            {...bem(null, null, {
                                "btn": true,
                                'btn-default': params.state != 0,
                                'btn-primary': params.state == 0
                            })}
                            onClick={() => {
                                this.getData(0)
                            }}>Para Validação
                        </a>
                        <a
                            href={`#/admin/partners/2`}
                            {...bem(null, null, {
                                "btn": true,
                                'btn-default': params.state != 2,
                                'btn-primary': params.state == 2
                            })}
                            onClick={() => {
                                this.getData(2)
                            }}>Aprovados
                        </a>

                        <a
                            href={`#/admin/partners/3`}
                            {...bem(null, null, {
                                "btn": true,
                                'btn-default': params.state != 3,
                                'btn-primary': params.state == 3
                            })}
                            onClick={() => {
                                this.getData(3)
                            }}>Rejeitados
                        </a>
                    </div>

                    {
                        isFetching && <div>Loading</div>
                    }

                    <div {...bem("list", null, "row")}>
                        {
                            values.map((contact, index) => {
                                return (
                                    <div className="col-md-3" key={index}>
                                        <EntityPartner state={params.state} {...contact}/>
                                    </div>
                                )
                            })
                        }

                    </div>

                    {
                        !hasValues && <h3>Não existe registos</h3>
                    }
                </div>
            </section>
        );
    }
}

export default Page;
