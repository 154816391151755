import React from "react"
import Popover from "react-popover"

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = { popoverIsOpen: false };
        this.togglePopover = this.togglePopover.bind(this);
    }

    togglePopover() {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState({
                popoverIsOpen: !this.state.popoverIsOpen
            })
        }
    }

    render() {
        const { children } = this.props;

        if (children.length != 2) {
            return <div></div>;
        }

        const popoverProps = {
            isOpen: this.state.popoverIsOpen,
            preferPlace: 'below',
            place: 'below',
            onOuterAction: () => this.togglePopover(),
            body: [children[1]]
        };

        return (
            <Popover {...popoverProps}>
                <div onClick={this.togglePopover}>
                    {children[0]}
                </div>
            </Popover>
        )
    }
}

export default Main
