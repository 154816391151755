import React from 'react';

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className="ff-admin-section ff-admin-section__dashboard">
                Admin Dashboards
            </section>
        );
    }
}

export default Page;