import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class DeleteEntity extends PureComponent {

    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
    }

    delete() {
        const { finishedHandler, list = [], selectedIds = [], action, entity } = this.props;

        const delAction = (id) => {
            return this.props.dispatch(action(entity, id))
                .then(() => {
                    this.setState({
                        isLoading: false
                    });
                });
        };

        const del = (pos) => {

            if (pos === selectedIds.length) {
                finishedHandler();
            } else {
                const id = list[selectedIds[pos]].id;

                delAction(id).then(() => {
                    del(pos + 1);
                });
            }
        };

        del(0);

    }

    render() {
        const { selectedIds = [] } = this.props;
        return (
            <div>
                <button
                    disabled={selectedIds.length === 0}
                    className="btn btn-primary"
                    onClick={this.delete}>Eliminar
                </button>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {}
};

export default connect(mapStateToProps)(DeleteEntity)
