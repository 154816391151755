import React from "react";
import MultiGrid from './MultiGrid';
import ProductGrid from './ProductGrid';
import { CalculateGrid } from '../../../Utils/GridUtil';
import BEMHelper from 'react-bem-helper';
import {
    CLIENT_TYPE_FEATURED,
    CLIENT_TYPE_GRID,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_SLIDER,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_TEXT_IMAGE
} from "../../../Utils/Consts";

const bem = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-page-sections'
});

const Image = ({ value }) => {
    return (<div {...bem('image')}>
        <img src={`/images/gallery/originals/${value}`}/>
    </div>);
};

const Text = ({ value, cols = [] }) => {
    const x = CalculateGrid(cols.length);
    return (
        <div {...bem('text')}>
            <h2 className="title">{value}</h2>

            <div {...bem('text', { single: cols.length === 1 }, 'row')}>
                {
                    cols.map((colData, index) => {
                        return (
                            <div
                                className={`col-md-${x}`}
                                key={index}>
                                <div dangerouslySetInnerHTML={{ __html: colData.value }}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

const Default = () => {
    return (<div>
        Default
    </div>);
};

const Separator = () => {
    return (<div>
        <hr/>
    </div>);
};

const GetInput = (type) => {
    switch (type) {
        case CLIENT_TYPE_TEXT:
            return Text;
            break;
        case CLIENT_TYPE_IMAGE:
            return Image;
            break;
        case CLIENT_TYPE_SLIDER:
            return MultiGrid;
            break;
        case CLIENT_TYPE_FEATURED:
            return ProductGrid;
            break;
        case CLIENT_TYPE_GRID:
            return GridImages;
            break;
        case CLIENT_TYPE_TEXT_IMAGE:
            return Welcome;
            break;
        case CLIENT_TYPE_SEPARATOR:
            return Separator;
            break;
        default:
            return Default;
    }
};

const PageSections = ({ sections = [] }) => {

    return (
        <div {...bem()}>
            {
                sections.map((sectionData, index) => {
                    const Component = GetInput(sectionData.typeOfComponent);
                    return (
                        <div key={index} {...bem('section')}>
                            <Component {...sectionData}/>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default PageSections;
