import React from 'react';
import FontAwesome from 'react-fontawesome';
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'ff-admin-entities-',
    name: 'jorney'
});

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.click = this.click.bind(this);
    }

    click() {
        this.props.selectHandler(this.props.index);
    }

    render() {
        const { isSelected, image, title } = this.props;
        return (
            <div onClick={this.click} {...bem(null, { selected: isSelected }, {
                'panel': true,
                'panel-primary': !isSelected,
                'panel-success': isSelected
            })}>

                <div className="panel-heading">
                    {
                        isSelected && <div className="select-icon">
                            <FontAwesome name="check"/>
                        </div>
                    }
                    <div className="panel-heading__container">
                        <h4>{title}</h4>
                    </div>
                </div>

                <div className="panel-body">
                    <div {...bem('container')}>
                        <div {...bem('image')}>
                            <img className="ff-image" src={`/images/gallery/thumbnails/${image}`}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page;
