import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Page from './Page';
import { PartnerService } from "../../../services";

export const NAME = 'PageDetailForm';

const mapStateToProps = (state) => {

    return {
        enableReinitialize: true,
        data: state[PartnerService.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getById: PartnerService.getById,
            accept: PartnerService.accept,
            reject: PartnerService.reject,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Page)
