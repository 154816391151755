import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Link } from "react-router-dom";

const bemProd = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-multi-grid-prod'
});

const Category = ({ index = 0, info }) => {
    const isReverse = index % 2 !== 0;
    return (
        <div {...bemProd(null, { 'reverse': isReverse }, 'row')}>
            <div className="col-xs-6">
                <div {...bemProd('image-container')}>
                    <Link to={`/categories/${info.category.id}`}>
                        <img src={`/images/gallery/thumbnails/${info.category.image}`}/>
                    </Link>
                </div>
            </div>
            <div className="col-xs-6">
                <div {...bemProd('info-container')}>
                    <h2>{info.category.name}</h2>
                    <h4>{info.category.description}</h4>
                </div>
            </div>
        </div>
    )
};

export default Category;
