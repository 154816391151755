import React from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom';
import { Field, SubmissionError } from 'redux-form';
import { AuthService } from '../../../../services'
import * as Validators from '../../../../admin/forms/utils/validators';
import InputError from "../../../../common/components/InputError";

const MyField = (props) => {
    const { validateMetaData = {}, icon, type = 'text', input, label, meta } = props;

    return (

        <div className="form-group">
            <div className="input-group">
                <span className="input-group-addon">
                    <i className={icon}></i>
                </span>
                <input className="form-control input-sm" {...input} type={type} placeholder={label}/>
            </div>
            <InputError {...meta} metadata={validateMetaData}/>
        </div>
    );
};

const GoToLogin = () => {

    return (

        <div className="form-group">
            <div className="input-group">
                A sua conta foi criada com sucesso. Click <Link to='/login'><b>aqui</b></Link> para fazer Login
            </div>
        </div>
    );
};

const Register = ({ success, submitted }) => {
    return (
        <div className="ff-form_login container">
            <h3 className="ff-form_authTitle">Nova conta</h3>


            <div className="row ff-form_row-sm-offset-3 ff-form_loginOr">
                <div className="col-xs-12 col-sm-6">
                    <hr className="ff-form_hrOr"/>
                </div>
            </div>

            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    <Field
                        validate={[Validators.required]}
                        label="Nome"
                        placeholder='Nome'
                        icon="fa fa-user"
                        type="text"
                        name='name'
                        component={MyField}/>
                    <Field
                        validate={[Validators.required]}
                        label="Apelido"
                        placeholder='Apelido'
                        icon="fa fa-user"
                        type="text"
                        name='last_name'
                        component={MyField}/>
                    <Field
                        validate={[Validators.required, Validators.email]}
                        label="E-mail"
                        icon="fa fa-user"
                        type="email"
                        name='email'
                        placeholder='E-mail'
                        component={MyField}/>

                    <Field
                        validate={[Validators.required, Validators.minLength6]}
                        validateMetaData={{ min: 6 }}
                        label="Palavra passe"
                        icon="fa fa-lock"
                        name='password'
                        placeholder='Palavra passe'
                        type='password'
                        component={MyField}/>

                    <Field
                        validate={[Validators.required, Validators.minLength6]}
                        validateMetaData={{ min: 6 }}
                        label="Repita a palavra passe"
                        placeholder='Repita a palavra passe'
                        icon="fa fa-lock"
                        name='password_confirmation'
                        type='password'
                        component={MyField}/>

                    {
                        success && submitted && <GoToLogin/>
                    }

                    <button
                        disabled={success && submitted}
                        className="btn btn-sm btn-primary btn-block"
                        type="submit">Criar
                    </button>
                </div>
            </div>
        </div>
    );
};

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            isLoading: false,
            submitted: false,
            error: false
        };

        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(credentials) {

        this.setState({
            success: false,
            isLoading: true,
            submitted: true,
            error: false
        });

        return this.props.dispatch(
            AuthService.register(credentials))
            .then(() => {
                this.setState(
                    {
                        success: true,
                        isLoading: false,
                        error: false
                    }
                );
            })
            .catch((errorData) => {
                const errors = [];
                errorData.error.map((error) => {
                    if (error === 'The email has already been taken.') {
                        errors.push({
                            email: 'unique',
                            _error: 'Register failed!'
                        });
                        return;
                    }

                    if (error === 'The password confirmation does not match.') {
                        errors.push({
                            password_confirmation: 'password_confirmation',
                            _error: 'Register failed!'
                        });
                        return;
                    }
                });

                this.setState(
                    {
                        success: false,
                        isLoading: false,
                        error: true
                    }
                );

                throw new SubmissionError(errors.pop())
            });

    }

    onSocialClick(event, data) {
        window.location.assign(`redirect/${data.as}`);
    }

    render() {
        const { isAuthenticated, isAdmin, location, handleSubmit } = this.props;
        const { success, submitted } = this.state;
        const { from } = location.state || { from: { pathname: '/' } };

        if (isAuthenticated) {
            //todo andre: Injectar as rotas e colocar por defeito a rota da primeira posição
            const to = isAdmin ? '/home' : from;
            return (
                <Redirect to={to}/>
            )
        }

        return (
            <section className="ff-main-section">
                <div className="contact">
                    <div className="container">
                        <div className="row">
                            <form className="ff-form_loginForm" onSubmit={handleSubmit(this.handlerSubmit)} noValidate>
                                <Register
                                    success={success}
                                    submitted={submitted}/>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Page.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Page;
