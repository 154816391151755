import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Link } from "react-router-dom";

const bemProd = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-multi-grid-prod'
});

const Product = ({ index = 0, info, is_publicity }) => {
    const isReverse = index % 2 !== 0;
    return (
        <div {...bemProd(null, { 'reverse': isReverse }, 'row')}>
            <div className="col-xs-6">
                <div {...bemProd('image-container')}>
                    {
                        is_publicity && <div>
                            <img src={`/images/gallery/thumbnails/${info.product.main_image}`}/>
                        </div>
                    }

                    {
                        !is_publicity && <Link to={`/products/${info.product.id}`}>
                            <img src={`/images/gallery/thumbnails/${info.product.main_image}`}/>
                        </Link>
                    }
                </div>
            </div>
            <div className="col-xs-6">
                <div {...bemProd('info-container')}>
                    <h2>{info.product.name}</h2>
                    <h4>{info.product.description}</h4>
                    {
                        !is_publicity && <span>Preço base {info.product.base_price}</span>
                    }
                </div>
            </div>
        </div>
    )
};

export default Product;
