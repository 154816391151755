import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Page from './Page';
import { orderBy } from "lodash";

export const NAME = 'GroupOfPagesForm';

const Form = reduxForm({
    form: NAME
})(Page);

const mapData = (data) => {

    if (data.id > 0) {
        data.pages.map((page)=>{
            page.name = page.page.name;
            page.id = page.page.id;
            page.type = "Page";
        })
        }
    return data;
};

const mapStateToProps = (state, props) => {

    return {
        enableReinitialize: true,
        initialValues: mapData(props.defaultValues)
    }
};

export default connect(mapStateToProps)(Form)
