import { combineReducers } from 'redux';
import { reducer as Form } from 'redux-form';
import Auth from './Auth';
import OrderDetail from './OrderDetail';
import persistStore from './persistStore';
import Orders from './Orders';
import * as Pages from '../../services/Pages';
import * as Page from '../../services/Page';
import * as PageInfo from '../../services/PageInfo';
import * as UserContactService from '../../services/UserContact';
import * as NewsService from '../../services/News';
import * as JorneyService from '../../services/Jorney';
import * as PartnerService from '../../services/Partner';

const RootReducer = combineReducers(
    {
        form: Form,
        Auth,
        persistStore,
        OrderDetail,
        Orders,
        [NewsService.REDUCER_NAME]: NewsService.reducer,
        [PartnerService.REDUCER_NAME]: PartnerService.reducer,
        [JorneyService.REDUCER_NAME]: JorneyService.reducer,
        [Pages.REDUCER_NAME]: Pages.reducer,
        [Page.REDUCER_NAME]: Page.reducer,
        [PageInfo.REDUCER_NAME]: PageInfo.reducer,
        [UserContactService.REDUCER_NAME]: UserContactService.reducer,
    }
);

export default RootReducer;
