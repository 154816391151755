import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServicePage from '../../../services/PageInfo';

const Prod = ({ select, isSelected, main_image, name }) => {
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/${main_image}`}/>
            </div>
            <label>Producto {name}</label>
        </a>
    );
};

const Cat = ({ select, isSelected, image, name }) => {
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/${image}`}/>
            </div>
            <label>Categoria {name}</label>
        </a>
    );
};

const Ext = ({ select, isSelected, image, name }) => {
    return (
        <a className={isSelected ? '' : 'ff-image-uploader__selected'}
           onClick={select}>
            <div className="ff-admin-en__image">
                <img src={`/images/gallery/thumbnails/${image}`}/>
            </div>
            <label>Extra {name}</label>
        </a>
    );
};

const CATEGORY_TYPE = 'Category';
const EXTRA_TYPE = 'Extra';
const PRODUCT_TYPE = 'Product';

class InputEntites extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { matrix: {} };
        this.successHandler = this.successHandler.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.selectExtra = this.selectExtra.bind(this);
    }

    successHandler() {
        const { matrix } = this.state;

        const result = Object.keys(matrix).map((key) => {
            return matrix[key];
        });

        this.props.successHandler(result);
    }

    getData() {
        this.props.getEntities('page-entities');
    }

    componentWillMount() {
        this.getData();
    }

    selectCategory(index) {
        const { pageEntities: { values } } = this.props;
        const { matrix } = this.state;
        if (matrix[`${CATEGORY_TYPE}${index}`]) {
            delete matrix[`${CATEGORY_TYPE}${index}`];
        } else {
            const data = { ...values.categories[index] };
            data.type = CATEGORY_TYPE;
            data.entity_id = data.id;
            data.isNotSaved = true;
            matrix[`${CATEGORY_TYPE}${index}`] = data;
        }

        this.setState({ matrix: { ...matrix } });
    }

    selectExtra(index) {
        const { pageEntities: { values } } = this.props;
        const { matrix } = this.state;
        if (matrix[`${EXTRA_TYPE}${index}`]) {
            delete matrix[`${EXTRA_TYPE}${index}`];
        } else {
            const data = { ...values.extras[index] };
            data.type = EXTRA_TYPE;
            data.entity_id = data.id;
            data.isNotSaved = true;
            matrix[`${EXTRA_TYPE}${index}`] = data;
        }

        this.setState({ matrix: { ...matrix } });
    }

    selectProduct(index) {
        const { pageEntities: { values } } = this.props;
        const { matrix } = this.state;
        if (matrix[`${PRODUCT_TYPE}${index}`]) {
            delete matrix[`${PRODUCT_TYPE}${index}`];
        } else {
            const data = { ...values.products[index] };
            data.type = PRODUCT_TYPE;
            data.entity_id = data.id;
            data.isNotSaved = true;

            matrix[`${PRODUCT_TYPE}${index}`] = data;
        }

        this.setState({ matrix: { ...matrix } });
    }

    render() {
        const { limit = -1, close, pageEntities: { values, isFetching } } = this.props;
        const { matrix } = this.state;

        if (isFetching) {
            return (<div>Loading</div>);
        }

        let label = "Selecione as entidades";
        if (limit !== -1) {
            label = `Selecione ${limit} entidades`;
        }

        return (
            <div className="ff-admin-form">
                <div className="ff-admin-form__header">
                    <h2>{label}</h2>
                </div>
                <div className="ff-admin-form__content ff-admin-en">
                    {
                        values.products.map((prod, index) => {
                            const selected = !matrix[`${PRODUCT_TYPE}${index}`];
                            return (
                                <Prod
                                    isSelected={selected}
                                    key={index}
                                    {...prod}
                                    select={() => {
                                        this.selectProduct(index);
                                    }}/>
                            );
                        })
                    }

                    {
                        values.categories.map((prod, index) => {
                            const selected = !matrix[`${CATEGORY_TYPE}${index}`];
                            return (
                                <Cat
                                    isSelected={selected}
                                    key={index}
                                    {...prod}
                                    select={() => {
                                        this.selectCategory(index);
                                    }}/>
                            );
                        })
                    }

                    {
                        values.extras.map((prod, index) => {
                            const selected = !matrix[`${EXTRA_TYPE}${index}`];
                            return (
                                <Ext
                                    isSelected={selected}
                                    key={index}
                                    {...prod}
                                    select={() => {
                                        this.selectExtra(index);
                                    }}/>
                            );
                        })
                    }
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                    <a/>
                </div>
                <div className="ff-admin-form__footer">
                    <hr/>
                    <div className="ff-admin-form__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xs"
                            onClick={close}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={Object.keys(this.state.matrix).length === 0}
                            className="btn btn-primary btn-xs"
                            onClick={() => {
                                this.setState({ matrix: {} })
                            }}>Limpar
                        </button>
                    </div>
                    <div className="ff-admin-form__footer-actions pull-right">
                        <button
                            onClick={this.successHandler}
                            disabled={Object.keys(this.state.matrix).length === 0}
                            className="btn  btn-primary btn-xs">Gravar
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pageEntities: state.PageEntities
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getEntities: ServicePage.get
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(InputEntites)