import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Page from './Page';

export const NAME = 'ForgotPasswordForm';

const Form = reduxForm({
    form: NAME
})(Page);

const mapStateToProps = (state) => {
    return {
        enableReinitialize: true,
        initialValues: state.Auth.user,
        isAuthenticated: state.Auth.isAuthenticated,
        isAdmin: state.Auth.isAdmin,
    }
};

export default connect(mapStateToProps)(Form)
