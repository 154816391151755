import React, { PureComponent } from 'react';
import EditEntity from '../edit-entity';
import { getEntityById } from '../../../services/crudService';
import { orderBy } from "lodash";

class CRUDEntity extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: true
        };
        this.refresh = this.refresh.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.getData = this.getData.bind(this);
        this.mapData = this.mapData.bind(this);
    }

    mapData(data) {
        const pages = data.pages.map((obj) => {
            obj['type'] = 'Page';
            obj.name = obj.page.name;
            obj.url_code = obj.page.url_code;
            obj.entity_id = obj.page.id;
            return obj;
        });

        const group_of_pages = data.header_group_of_pages.map((obj) => {
            obj['type'] = 'GroupOfPages';
            obj.name = obj.group_of_pages[0].description;
            obj.entity_id = obj.group_of_pages[0].id;
            return obj;
        });

        const result = {};
        result.values = orderBy([...pages, ...group_of_pages], ['index'], ['asc']);
        return result;

    }

    getData() {
        const { dispatch, entity } = this.props;
        const _self = this;
        this.setState({
            isLoading: true
        });

        dispatch(getEntityById(entity, 1))
            .then((res) => {
                const data = _self.mapData(res);
                this.setState({
                    isLoading: false,
                    data
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            data: {}
        });
        this.getData();
    }

    refresh() {
        this.setState({ selectedIds: [] });
        this.getData();
    }

    selectHandler(position) {
        const arr = this.state.selectedIds;
        const index = arr.indexOf(position);

        if (index > -1) {
            arr.splice(index, 1);
            this.setState({
                selectedIds: [...arr]
            });
        } else {
            this.setState({
                selectedIds: [...arr, position]
            });
        }
    }

    render() {
        const { entity, title, form, entityView, } = this.props;
        const { data = {}, isLoading } = this.state;
        const EntityView = entityView;

        if (isLoading) {
            return <div>Loading</div>
        }

        return (
            <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4>{title}</h4>
                            <div className="actions">
                                <EditEntity
                                    defaultEvent={false}
                                    entity={entity}
                                    addHandler={this.refresh}
                                    list={data}
                                    selectedIds={[1]}
                                    form={form}/>

                            </div>
                        </div>
                        <div className="panel-body">

                            <div className="row">
                                <div className="col-xs-12 col-md-12 col-lg-12">
                                    <EntityView {...data}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CRUDEntity;
