import React from 'react';
import StyleButton from "./StyleButton";

export const fontStyleMap = {
    HandType: {
        fontFamily: 'Dancing Script'
    },
};

const LETTERS = [
    { label: 'Letra á mão', style: 'HandType' },
];

export const FontControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div>
            {
                LETTERS.map(type =>
                    <StyleButton
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}/>
                )
            }
        </div>
    );
};