import React, { PureComponent } from 'react';
import Filter from "./Filter";

class Filters extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { values: [] };
    }

    render() {
        const { selectedFilters, filterHandler, listOfFilters } = this.props;
        return (
            <div className="ff-crud-filters">
                {
                    listOfFilters.map((filter, index) => {
                        return <Filter key={index} selectedFilters={selectedFilters} filterHandler={filterHandler} {...filter}/>
                    })
                }
            </div>
        );
    }
}

export default Filters;
