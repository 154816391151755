import React, { PureComponent } from 'react';
import BEMHelper from 'react-bem-helper';
import Slider from './Slider';
import Category from './Category';
import Product from './Product';

const bem = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-multi-grid'
});

class MultiGrid extends PureComponent {
    render() {
        const { value, values = [], is_publicity } = this.props;

        return (
            <div {...bem(null, null, 'ff-equal')}>
                {
                    value && <div dangerouslySetInnerHTML={{ __html: value }}/>
                }
                <div className="row">
                    <div className="col-md-6">
                        <div  {...bem('slick-container')}>
                            <div  {...bem('slick')}>
                                <Slider prods={values} is_publicity={is_publicity}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            values.slice(0, 4).map((info, index) => {
                                return info.type === 'Product' ?
                                    <Product
                                        key={index}
                                        is_publicity={is_publicity}
                                        info={info}
                                        index={index}/>
                                    :
                                    <Category
                                        is_publicity={is_publicity}
                                        key={index}
                                        info={info}
                                        index={index}/>

                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MultiGrid;
