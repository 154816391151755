import Http from '../../Http';
import { toast } from 'react-toastify';

class Base {
    constructor(entity, initialState) {
        this.entity = entity;
        this.initialState = initialState;
        this.GET = `${entity}_GET`;
        this.GET_BY_ID = `${entity}_GET_BY_ID`;
        this.PUT = `${entity}_PUT`;
        this.CREATE = `${entity}_CREATE`;
        this.FETCH = `${entity}_FETCH`;
        this.ERROR = `${entity}_ERROR`;
        this.DELETE = `${entity}_DELETE`;
        this.RESET = `${entity}_RESET`;

        this.get = this.get.bind(this);
        this.getById = this.getById.bind(this);
        this.fetch = this.fetch.bind(this);
        this.put = this.put.bind(this);
        this.error = this.error.bind(this);
        this.delete = this.delete.bind(this);
        this.put = this.put.bind(this);
        this.post = this.post.bind(this);
        this.reset = this.reset.bind(this);

        this.getAsync = this.getAsync.bind(this);
        this.getByIdAsync = this.getByIdAsync.bind(this);
        this.putAsync = this.putAsync.bind(this);
        this.deleteAsync = this.deleteAsync.bind(this);
        this.postAsync = this.postAsync.bind(this);

        this.reducer = this.reducer.bind(this);

    }

    reducer(state = this.initialState, action = {}) {
        const withInitialState = {
            ...state,
            initialState: this.initialState
        };
        switch (action.type) {
            case this.GET: {
                const defaultValues = state.isSingleObject ? {} : [];
                return {
                    ...withInitialState,
                    values: action.data || defaultValues,
                    isFetching: false,
                    isError: false
                };
            }
            case this.GET_BY_ID: {
                return {
                    ...withInitialState,
                    valuesById: action.data,
                    isFetching: false,
                    isError: false
                };
            }
            case this.PUT: {
                const values = state.isSingleObject ? action.data : state.values.concat(action.data || []);

                return {
                    ...withInitialState,
                    isError: false,
                    values
                };
            }
            case this.CREATE: {
                return {
                    ...withInitialState,
                    isError: false,
                    values: state.isSingleObject ? action.data : state.values.concat(action.data || [])
                };
            }
            case this.FETCH: {
                return {
                    ...withInitialState,
                    isFetching: true,
                    isError: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            case this.RESET: {
                return {
                    ...withInitialState,
                    isFetching: false,
                    isError: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            case this.DELETE: {
                return {
                    ...withInitialState,
                    isError: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            case this.ERROR: {
                return {
                    ...withInitialState,
                    isError: true,
                    isFetching: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            default: {
                return withInitialState;
            }
        }
    }

    get(data) {
        return {
            type: this.GET,
            data
        };
    }

    getById(data) {
        return {
            type: this.GET_BY_ID,
            data
        };
    }

    fetch() {
        return {
            type: this.FETCH
        };
    }

    error() {
        return {
            type: this.ERROR
        };
    }

    delete() {
        return {
            type: this.DELETE
        };
    }

    put(data) {
        return {
            type: this.PUT,
            data
        };
    }

    post(data) {
        return {
            type: this.CREATE,
            data
        };
    }

    reset() {
        return {
            type: this.RESET
        };
    }

    getAsync(url, params = {}) {
        return (dispatch) => {
            //dispatch(this.fetch());
            return new Promise((resolve, reject) => {
                Http.get(url, { params })
                    .then((res) => {
                        dispatch(this.get(res.data));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        dispatch(this.error());
                        return reject({ statusCode });
                    })
            })
        };
    }

    getByIdAsync(url, params = {}) {
        return (dispatch) => {
            //dispatch(this.fetch());
            return new Promise((resolve, reject) => {
                Http.get(url, { params })
                    .then((res) => {
                        dispatch(this.getById(res.data));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        dispatch(this.error());
                        return reject({ statusCode });
                    })
            })
        };
    }

    putAsync(url, data) {
        return (dispatch) => (
            new Promise((resolve, reject) => {
                Http.put(url, data)
                    .then((res) => {
                        toast.success("Gravado com sucesso", {
                            position: toast.POSITION.TOP_RIGHT
                        });

                        dispatch(this.put(data));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        toast.error("Ocorreu um errro na gravação", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        dispatch(this.error());
                        return reject({ statusCode });

                    })
            })
        );
    }

    deleteAsync(url, id) {
        return (dispatch) => (
            new Promise((resolve, reject) => {
                Http.delete(url)
                    .then((res) => {
                        dispatch(this.delete());
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        dispatch(this.error());
                        return reject({ statusCode });
                    })
            })
        );
    }

    postAsync(url, data) {
        return (dispatch) => (
            new Promise((resolve, reject) => {
                Http.post(url, data)
                    .then((res) => {
                        dispatch(this.post(res.data));
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        dispatch(this.error());
                        return reject({ statusCode });
                    })
            })
        );
    }
}

export default Base;
