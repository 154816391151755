import React from 'react';
import Modal from '../../components/modal';
import SliderFormModal from "./SliderFormModal";
import InputError from "../../../common/components/InputError";

/**
 * Get component by type.
 * @param type of component
 * @returns {*} Component module
 */
class RenderSlider extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.onClick = this.onClick.bind(this);
        this.successHandler = this.successHandler.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
    }

    onClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    successHandler(newValues) {
        this.setState({ isOpen: !this.state.isOpen });
        const { fields } = this.props;

        newValues.map((val) => {
            fields.push(val);
        });
    }

    moveRight(fields, index) {
        fields.move(index, index + 1)

    };

    moveLeft(fields, index) {
        fields.move(index, index - 1)
    };

    render() {
        const { fields, meta, metadata } = this.props;
        return (
            <div className="ff-adm-slider-header">
                <div className="row">
                    {
                        fields.map((section, index) => {
                            const sectionData = fields.get(index);
                            return (
                                <div className="ff-adm-slider-header__item" key={index}>
                                    <div className="ff-adm-slider-header__item-container">

                                        {
                                            sectionData.description &&
                                            <h4 className="title">{sectionData.type}: {sectionData.description}</h4>
                                        }
                                        {
                                            sectionData.name &&
                                            <h4 className="title">{sectionData.type}: {sectionData.name}</h4>
                                        }

                                        <div className="actions">
                                            <button
                                                disabled={index === 0}
                                                className="btn btn-sm btn-default"
                                                type="button"
                                                title="Remove Member"
                                                onClick={() => this.moveLeft(fields, index)}>
                                                <i className="glyphicon glyphicon-arrow-left"></i>
                                            </button>
                                            <button
                                                disabled={index === fields.length - 1}
                                                className="btn btn-sm btn-default"
                                                type="button"
                                                title="Remove Member"
                                                onClick={() => this.moveRight(fields, index)}>
                                                <i className="glyphicon glyphicon-arrow-right"></i>
                                            </button>
                                            <button
                                                className="btn btn-sm btn-default"
                                                type="button"
                                                title="Remove Member"
                                                onClick={() => fields.remove(index)}>
                                                <i className="glyphicon glyphicon-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-xs-3 ff-adm-slider-header__empty-item">
                        <div className="ff-adm-slider-header__empty-item-container" onClick={this.onClick}>
                            <i className="glyphicon glyphicon-plus"></i>
                        </div>
                    </div>
                </div>
                <InputError {...meta} metadata={metadata}/>
                <Modal isOpen={this.state.isOpen}>
                    <SliderFormModal
                        close={this.onClick}
                        successHandler={this.successHandler}/>
                </Modal>
            </div>
        )
    }
};
export default RenderSlider;
