import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { PartnerService } from "../../../services";
import * as Validators from "../../../admin/forms/utils/validators";
import InputError from "../../../common/components/InputError";

const InputText = ({ metadata = {}, type = 'text', input, label, meta }) => {
    return (
        <div>
            <input className="textbox" {...input} type={type} placeholder={label}/>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const InputTextArea = ({ metadata = {}, input, label, meta }) => {

    return (
        <div>
            <textarea {...input} placeholder={label}/>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const ConForm = ({ myHandleSubmit, handleSubmit, pristine, submitting }) => {
    return (
        <div className="col-md-8 col-md-offset-2">
            <div className="contact-form">
                <form onSubmit={handleSubmit(myHandleSubmit)}>
                    <div>
                        <label className="label">NOME</label>
                        <Field
                            metadata={{ max: 15 }}
                            validate={[Validators.required, Validators.maxLength15]}
                            name="name"
                            component={InputText}
                            type="text"/>
                    </div>
                    <div>
                        <label className="label">Apelido</label>
                        <Field
                            metadata={{ max: 15 }}
                            validate={[Validators.required, Validators.maxLength15]}
                            name="last_name"
                            component={InputText}
                            type="text"/>
                    </div>
                    <div>
                        <label className="label">E-MAIL</label>
                        <Field
                            validate={[Validators.required, Validators.email]}
                            name="email"
                            component={InputText}
                            type="text"
                            className="textbox"/>
                    </div>
                    <div>
                        <label className="label">TELEMOVEL</label>
                        <Field
                            metadata={{ max: 15 }}
                            validate={[Validators.required, Validators.maxLength15]}
                            name="phone"
                            component={InputText}
                            type="text"
                            className="textbox"/>
                    </div>
                    <div>
                        <label className="label">Carta de motivacao</label>
                        <Field
                            metadata={{ max: 250 }}
                            validate={[Validators.required, Validators.maxLength250]}
                            name="msg"
                            component={InputTextArea}/>
                    </div>
                    <div>
                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className="btn  btn-primary btn-xl">ENVIAR
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

class Contacts extends PureComponent {

    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values = {}) {
        const { postMessage, reset } = this.props;

        return postMessage(values)
            .then((res) => {
                reset();
                this.setState({
                    isLoading: false
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })

    }

    render() {
        const { handleSubmit, pristine, submitting } = this.props;
        return (
            <section className="ff-main-section">
                <div className="container">
                    <div className="section group">
                        <ConForm
                            myHandleSubmit={this.handlerSubmit}
                            handleSubmit={handleSubmit}
                            pristine={pristine}
                            submitting={submitting}/>
                    </div>
                </div>
            </section>
        );
    }
}

export const NAME = 'FormsContact';

const Form = reduxForm({
    form: NAME
})(Contacts);

const mapStateToProps = (state) => {
    return {
        enableReinitialize: true,
        initialValues: state[PartnerService.REDUCER_NAME].formValues
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            postMessage: PartnerService.post,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Form)
