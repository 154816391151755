import React from 'react'
import BEMHelper from 'react-bem-helper';
import SectionTypeDate from "../PageSections/date";

const bem = new BEMHelper({
    prefix: 'spor-',
    name: 'feed-news2'
});

class Brand extends React.PureComponent {

    render() {
        const { render_type, description, values = [] } = this.props;
        return (
            <section {...bem()}>
                <div {...bem('title')}>
                    <div dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        {
                            [...values].slice(0, 3).map((valObj, index) => {

                                return (
                                    <article key={index} {...bem('article-2-col')}>
                                        <div>
                                            <div className="info">
                                                <h3>{valObj.title}</h3>
                                                <SectionTypeDate date={valObj.date}/>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={`/images/gallery/originals/${valObj.image}`}/>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </div>

                    <div className="col-lg-6">
                        <article {...bem('article-1-col')}>
                            Artigo
                        </article>
                    </div>
                </div>
            </section>
        );
    }
}

export default Brand;
