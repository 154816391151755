import React from 'react';
import Crud from '../../components/crud';
import { NewsAndEventsForm } from '../../forms';
import { EntityNews } from '../../components/entities';

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch } = this.props;
        const defaultValues = { date: 1, show: false };
        return (
            <section className="ff-admin-section">
                <div className="container-fluid">
                    <Crud
                        title={"Noticias e Eventos"}
                        dispatch={dispatch}
                        entity="news-and-events"
                        defaultValues={defaultValues}
                        entityView={EntityNews}
                        form={NewsAndEventsForm}/>
                    <br/>
                </div>
            </section>
        );
    }
}

export default Page;
