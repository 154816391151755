import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";

const Prod = ({ info, is_publicity }) => {
    return (
        <div className="ff-portfolio__product">
            {
                is_publicity && <div>
                    <figure className="ff-portfolio__product-img-container">
                        <img className="ff-portfolio__product-img img-responsive"
                             src={`/images/gallery/thumbnails/${info.product.main_image}`}
                             alt={`Product ${info.product.id}`}/>
                        <figcaption>
                            <div>{info.product.name}</div>
                        </figcaption>
                    </figure>
                </div>
            }

            {
                !is_publicity && <Link to={`/products/${info.product.id}`}>
                    <figure className="ff-portfolio__product-img-container">
                        <img className="ff-portfolio__product-img img-responsive"
                             src={`/images/gallery/thumbnails/${info.product.main_image}`}
                             alt={`Product ${info.product.id}`}/>
                        <figcaption>
                            <div>{info.product.name}</div>
                        </figcaption>
                    </figure>
                </Link>
            }

            <div className="text-center">
                <h4>{info.product.name}</h4>
                {
                    !is_publicity && <p className="product_price">{info.product.base_price}€ + Portes de envio</p>
                }
            </div>
        </div>
    )
};
const Cat = ({ info, is_publicity }) => {
    return (
        <div className="ff-portfolio__product">
            <Link to={`/categories/${info.category.id}`}>
                <figure className="ff-portfolio__product-img-container">
                    <img className="ff-portfolio__product-img img-responsive"
                         src={`/images/gallery/thumbnails/${info.category.image}`}
                         alt={`Product ${info.category.id}`}/>
                    <figcaption>
                        <div>{info.category.name}</div>
                    </figcaption>
                </figure>
            </Link>
            <div className="text-center">
                <h4>{info.category.name}</h4>
            </div>
        </div>
    )
};

class Home extends PureComponent {
    render() {
        const { value, values = [], is_publicity = false } = this.props;
        return (
            <section className="ff-cli-prod-grid">

                {
                    value && <div dangerouslySetInnerHTML={{ __html: value }}/>
                }

                <div className="row">
                    {
                        values.map((prod, index) => {

                            return (
                                <div key={index} className="col-xs-6 col-md-3 col-lg-3">
                                    {
                                        prod.type === 'Product' ?
                                            <Prod
                                                info={prod}
                                                is_publicity={is_publicity}
                                                index={index}/>
                                            :
                                            <Cat
                                                info={prod}
                                                is_publicity={is_publicity}
                                                index={index}/>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </section>
        );
    }
}

export default Home;