import React, { PureComponent } from 'react';
import { TYPE_MULTI } from "../../../Utils/Consts";
import SimpleMultiSelect from "../simple-multi-select";

class Filter extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { values: [] };
        this.changeHandler = this.changeHandler.bind(this);
    }

    changeHandler(selectValues) {
        const { filterHandler, prop } = this.props;
        this.setState({ values: selectValues });
        filterHandler(prop, selectValues)
    }

    render() {
        const { label, type, options, selectedFilters } = this.props;
        let Comp = null;

        if (type === TYPE_MULTI) {
            Comp = <SimpleMultiSelect
                label={label}
                filterHandler={this.changeHandler}
                fields={this.state.values}
                options={options}/>;
        }

        return (
            <div>
                {Comp}
            </div>
        );
    }
}

export default Filter;
