import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServicePage from '../../../services/Page';
import { PageSectionsLayout } from "../../components";
import { Redirect } from "react-router";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData(url_code) {
        this.props.getPage({ url_code });
    }

    componentWillMount() {
        const { match: { params } } = this.props;
        if (params.pageId) {
            this.getData(params.pageId);
        }
    }

    componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        const { match: { params: { pageId } } } = prevProps;

        if (params.pageId !== pageId) {
            this.getData(params.pageId);
        }
    }

    render() {
        const { match: { params }, Page: { isFetching, values, isError } } = this.props;

        if (!params.pageId) {
            return <Redirect to='/pages/home' replace/>;
        }

        if (isFetching) {
            return <div></div>;
        }

        if (isError === true) {
            return <Redirect to='/404' replace/>;
        }

        return (
            <main className="ff-page-info">
                <PageSectionsLayout showComponentInfo={false} {...values}/>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Page: state[ServicePage.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getPage: ServicePage.getInfo
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Index)
