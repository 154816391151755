import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { UserContactService } from "../../../services";
import * as Validators from "../../../admin/forms/utils/validators";
import InputError from "../../../common/components/InputError";

const InputText = ({ metadata = {}, type = 'text', input, label, meta }) => {
    return (
        <div>
            <input className="textbox" {...input} type={type} placeholder={label}/>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const InputTextArea = ({ metadata = {}, input, label, meta }) => {

    return (
        <div>
            <textarea {...input} placeholder={label}/>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const BasicInfo = () => {
    return (
        <div className="col-md-3">
            <div className="contact_info">
                <h4>Onde estamos</h4>
                <div className="map">
                    <iframe
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.1209468824927!2d-9.132298184795145!3d38.76092987959195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19324ea0c4ed27%3A0xcfbf2812fa420250!2sAv.+Alm.+Gago+Coutinho+151%2C+1700-033+Lisboa!5e0!3m2!1spt-PT!2spt!4v1556564470176!5m2!1spt-PT!2spt">
                    </iframe>
                    <br/>
                    <small><a
                        target="_blank"
                        href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.1209468824927!2d-9.132298184795145!3d38.76092987959195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19324ea0c4ed27%3A0xcfbf2812fa420250!2sAv.+Alm.+Gago+Coutinho+151%2C+1700-033+Lisboa!5e0!3m2!1spt-PT!2spt!4v1556564470176!5m2!1spt-PT!2spt"
                        style={{ color: '#666', textAlign: 'left', fontSize: '0.85em' }}>Ver no
                        google maps</a></small>
                </div>
            </div>
            <div className="company_address">

            </div>
        </div>
    )
};

const ConForm = ({ myHandleSubmit, handleSubmit, pristine, submitting, description }) => {
    return (
        <div className="col-md-9">
            <div className="contact-form">
                <form onSubmit={handleSubmit(myHandleSubmit)}>
                    <div>
                        <label className="label">NOME</label>
                        <Field
                            metadata={{ max: 15 }}
                            validate={[Validators.required, Validators.maxLength15]}
                            name="name"
                            component={InputText}
                            type="text"/>
                    </div>
                    <div>
                        <label className="label">E-MAIL</label>
                        <Field
                            validate={[Validators.required, Validators.email]}
                            name="email"
                            component={InputText}
                            type="text"
                            className="textbox"/>
                    </div>
                    <div>
                        <label className="label">TELEMOVEL</label>
                        <Field
                            metadata={{ max: 15 }}
                            validate={[Validators.required, Validators.maxLength15]}
                            name="phone"
                            component={InputText}
                            type="text"
                            className="textbox"/>
                    </div>
                    <div>
                        <label className="label">MENSAGEM</label>
                        <Field
                            metadata={{ max: 250 }}
                            validate={[Validators.required, Validators.maxLength250]}
                            name="msg"
                            component={InputTextArea}/>
                    </div>
                    <div>
                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className="btn  btn-primary btn-xl">ENVIAR
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {
                description && <div dangerouslySetInnerHTML={{ __html: description }}/>
            }
        </div>
    )
};

class Contacts extends PureComponent {

    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values = {}) {
        const { postMessage, reset } = this.props;

        return postMessage(values)
            .then((res) => {
                reset();
                this.setState({
                    isLoading: false
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })

    }

    render() {
        const { handleSubmit, pristine, submitting, description, value } = this.props;
        return (
            <section className="ff-main-section">
                <div className="contact">
                    <div className="container">
                        {
                            value && <div dangerouslySetInnerHTML={{ __html: description }}/>
                        }
                        <div className="section group">
                            <BasicInfo/>
                            <ConForm
                                description={description}
                                myHandleSubmit={this.handlerSubmit}
                                handleSubmit={handleSubmit}
                                pristine={pristine}
                                submitting={submitting}/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export const NAME = 'FormsContact';

const Form = reduxForm({
    form: NAME
})(Contacts);

const mapStateToProps = (state) => {
    return {
        enableReinitialize: true,
        initialValues: state[UserContactService.REDUCER_NAME].formValues
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            postMessage: UserContactService.post,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Form)
