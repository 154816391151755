import React from 'react';
import { Link } from "react-router-dom";

const Contact = (props) => {
    const { id, name, email, phone, state, updated_at } = props;
    const styleByState = state === 0 ? 'btn-default' : 'btn-success';
    return (
        <div className="row ff-user-contact__item">
            <div className="col-md-1">
                <label className="font-weight-bold">#{id}</label>
            </div>
            <div className="col-md-3">
                <span>{name}</span>
            </div>
            <div className="col-md-3">
                <span>{email}/{phone}</span>
            </div>
            <div className="col-md-3">
                <span>{updated_at}</span>
            </div>
            <div className="col-md-2">
                <Link
                    className={`btn btn-small ${styleByState}`}
                    to={`/admin/user-contacts/${props.id}`}>Ver</Link>
                {state !== 0 && <label>(Lida)</label>}
            </div>
        </div>
    );
};

const ContactHeader = () => {
    return (
        <div className="row">
            <div className="col-md-1">
                <label className="font-weight-bold"></label>
            </div>
            <div className="col-md-3">
                <label className="font-weight-bold">Nome</label>
            </div>
            <div className="col-md-3">
                <label className="font-weight-bold">Contactos</label>
            </div>
            <div className="col-md-3">
                <label className="font-weight-bold">Data</label>
            </div>
            <div className="col-md-2">
            </div>
        </div>
    );
};

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData() {
        this.props.getAllMessages();
    }

    componentWillMount() {
        this.getData();
    }

    render() {
        const { data: { isFetching, values } } = this.props;

        if (isFetching) {
            return <div>Loading</div>;
        }

        return (
            <section className="ff-admin-section ff-adm-page-detail container">
                <h3>Contactos</h3>

                <ContactHeader/>

                {
                    values.map((contact, index) => {
                        return (
                            <Contact key={index} {...contact}/>
                        )
                    })
                }
            </section>
        );
    }
}

export default Page;
