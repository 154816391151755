import React, {Component} from 'react';
import {Field} from "redux-form";
import {
    CLIENT_TYPE_FORM,
    CLIENT_TYPE_GRID,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_NEWS_EVENTS,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_TEXT_IMAGE,
    CLIENT_TYPE_VIDEO
} from "../../../Utils/Consts"
import InputError from "../../../common/components/InputError";
import GetInput from "./Forms";

class RenderSections extends Component {

    constructor(props) {
        super(props);
        this.state = {show: true};
    }

    render() {
        const {fields, meta, metadata, pageInfo} = this.props;

        const newSection = {
            typeOfComponent: CLIENT_TYPE_TEXT,
            is_publicity: false,
            is_fullscreen: false,
            is_banner: false,
            playing: true,
        };

        const _self = this;

        return (
            <div className="ff-adm-page-detail__sections">
                <div className="ff-adm-page-detail__actions">
                    <button
                        className="btn btn-xs btn-default"
                        type="button"
                        onClick={() => fields.unshift(newSection)}>
                        Adicionar seccao
                    </button>
                </div>
                {
                    fields.map((section, index) => {
                        const sectionData = fields.get(index);
                        return (
                            <div className="ff-adm-page-detail__section-container" key={index}>
                                <div className="ff-adm-page-detail__section">
                                    <div className="actions pull-right">
                                        <button
                                            disabled={index === 0}
                                            className="btn btn-xs btn-default"
                                            type="button"
                                            title="Remove Member"
                                            onClick={() => {
                                                _self.setState({show: false});
                                                fields.move(index, index - 1);
                                                setTimeout(() => {
                                                    _self.setState({show: true});
                                                }, 0)
                                            }}>
                                            <i className="glyphicon glyphicon-arrow-up"></i>
                                        </button>
                                        <button
                                            disabled={index === fields.length - 1}
                                            className="btn btn-xs btn-default"
                                            type="button"
                                            title="Remove Member"
                                            onClick={() => {
                                                _self.setState({show: false});
                                                fields.move(index, index + 1);
                                                setTimeout(() => {
                                                    _self.setState({show: true});
                                                }, 0)
                                            }}>
                                            <i className="glyphicon glyphicon-arrow-down"></i>
                                        </button>
                                        <button
                                            className="btn btn-xs btn-default"
                                            type="button"
                                            title="Remove Member"
                                            onClick={() => fields.remove(index)}>
                                            <i className="glyphicon glyphicon-trash"></i>
                                        </button>
                                    </div>
                                    <h4>Secção #{index + 1}</h4>
                                    <div
                                        className="form-group">
                                        <label>Selecione o componente</label>
                                        <div>
                                            <Field
                                                className="form-control"
                                                name={`${section}.typeOfComponent`}
                                                component="select">
                                                <option/>
                                                <option value={CLIENT_TYPE_TEXT}>Texto Livre</option>
                                                <option value={CLIENT_TYPE_IMAGE}>Imagem</option>
                                                <option value={CLIENT_TYPE_SEPARATOR}>Separador</option>
                                                <option value={CLIENT_TYPE_TEXT_IMAGE}>Texto e Imagem</option>
                                                <option value={CLIENT_TYPE_NEWS_EVENTS}>Noticias e Eventos</option>
                                                <option value={CLIENT_TYPE_FORM}>Formularios</option>
                                                <option value={CLIENT_TYPE_VIDEO}>Video</option>
                                                <option value={CLIENT_TYPE_GRID}>Galeria</option>
                                            </Field>
                                        </div>
                                    </div>

                                    {
                                        GetInput(pageInfo, section, sectionData.typeOfComponent, this.state.show)
                                    }
                                </div>

                                <div className="ff-adm-page-detail__actions">
                                    {
                                        1 == 2 && <div className="pull-right">
                                            <button
                                                className="btn btn-xs btn-default"
                                                type="button"
                                                onClick={() => {
                                                    const indexI = index + 0;
                                                    console.log("-------------->", indexI)
                                                    fields.insert(indexI, newSection)
                                                }}>
                                                <i className="glyphicon glyphicon-plus"></i>
                                            </button>
                                        </div>
                                    }

                                </div>
                                <InputError {...meta} metadata={metadata}/>
                            </div>
                        )
                    })
                }
                <div className="ff-adm-page-detail__actions">
                    <button
                        className="btn btn-xs btn-default"
                        type="button"
                        onClick={() => fields.push(newSection)}>
                        Adicionar seccao
                    </button>
                </div>
            </div>);
    }
};

export default RenderSections;
