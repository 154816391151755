import React, { Component } from 'react';

class Index extends Component {

    render() {
        const { image, value, text_align = 'right' } = this.props;
        const classStyle = text_align === 'right' ? 'img-right' : 'img-left';
        return (
            <section id="features" className="smapor-image">
                <img className={classStyle} src={`/images/gallery/originals/${image}`}/>
                <div className={'smapor-image__text'}>
                    <p dangerouslySetInnerHTML={{ __html: value }}></p>
                </div>
            </section>
        );
    }
}

export default Index;
