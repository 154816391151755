import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Page from './Page';
import { UserContactService } from "../../../services";

export const NAME = 'PageDetailForm';

const mapStateToProps = (state) => {

    return {
        enableReinitialize: true,
        data: state[UserContactService.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getById: UserContactService.getById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Page)
