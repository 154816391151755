import React from 'react';
import BEMHelper from 'react-bem-helper';
import { CRUDService } from './../../../../services'
import { FieldArray } from "redux-form";

const bem = new BEMHelper({
    prefix: 'ff-admin-input-',
    name: 'personalise'
});

const NewField = ({ fields }) => {

    return (
        <div>
            NewField
        </div>
    );
};

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { listOfFlowers: [], listOfSizes: [], isLoading: true };
        this.getData = this.getData.bind(this);
    }

    getData() {
        const { dispatch } = this.props;
        this.setState({
            isLoading: true
        });

        dispatch(CRUDService.getListEntities('extras'))
            .then(
                (listOfExtras) => {
                    this.setState({
                        listOfExtras, isLoading: false
                    });
                })
            .catch(
                () => {
                    this.setState({
                        isLoading: false
                    });
                });
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
            list: [],
            selectedIds: this.state.selectedIds
        });
        // this.getData();
    }

    render() {
        const { listOfExtras = [] } = this.state;
        return (
            <div {...bem()}>
                <div className="row">
                    <div className="col-xs-12">
                        <FieldArray
                            listData={listOfExtras}
                            name="personalise"
                            component={NewField}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;