import React from 'react'
import BEMHelper from 'react-bem-helper';
import InputError from "../../../../common/components/InputError";

const bem = new BEMHelper({
    prefix: 'ff-admin-input-',
    name: 'text'
});

class Image extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { type = 'text', input, label, metadata, meta } = this.props;

        return (
            <div {...bem()}>
                <div className="form-group">
                    <label>{label}</label>
                    <div>
                        <input className="form-control" {...input} type={type} placeholder={label}/>
                        <InputError {...meta} metadata={metadata}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Image;