const TYPE_SELECTION = 'selection';
const TYPE_SINGLE = 'singleSelection';
const TYPE_MULTI = 'multiSelection';

const CLIENT_TYPE_TEXT = 'Text';
const CLIENT_TYPE_IMAGE = 'Image';
const CLIENT_TYPE_SLIDER = 'Slider';
const CLIENT_TYPE_FEATURED = 'Featured';
const CLIENT_TYPE_GRID = 'Grid';
const CLIENT_TYPE_TEXT_IMAGE = 'TextImage';
const CLIENT_TYPE_NEWS_EVENTS = 'NewsAndEvents';
const CLIENT_TYPE_SEPARATOR = 'Separator';
const CLIENT_TYPE_CONTACTS_FORM = 'Contact';
const CLIENT_TYPE_PARTNER_FORM = 'Partner';
const CLIENT_TYPE_FORM = 'Form';
const CLIENT_TYPE_VIDEO = 'Video';

export {
    CLIENT_TYPE_FORM,
    CLIENT_TYPE_CONTACTS_FORM,
    CLIENT_TYPE_PARTNER_FORM,
    TYPE_SELECTION,
    TYPE_SINGLE,
    TYPE_MULTI,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_SLIDER,
    CLIENT_TYPE_FEATURED,
    CLIENT_TYPE_GRID,
    CLIENT_TYPE_TEXT_IMAGE,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_NEWS_EVENTS,
    CLIENT_TYPE_VIDEO
}
