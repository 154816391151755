import React from 'react'
import BEMHelper from 'react-bem-helper';
import SectionTypeDate from "../PageSections/date";

const bem = new BEMHelper({
    prefix: 'spor-',
    name: 'feed-news-by-categories'
});

const Article = ({ image, title, date }) => {
    return (
        <div className="col-lg-6">
            <article {...bem('article')}>
                <div {...bem('article-img-container')}>
                    <img src={`/images/gallery/originals/${image}`}/>
                </div>
                <div {...bem('article-info')}>
                    <h3>{title}</h3>
                    <SectionTypeDate date={date}/>
                </div>
            </article>
        </div>
    );
};

const Filter = () => {
    return (
        <div {...bem('header')}>
            <h2 className="title">Title</h2>
            <div {...bem('categories')}>
                <span className="category">CAT1</span>
                <span className="category">CAT2</span>
                <span className="category">CAT3</span>
                <span className="category--last">CAT4</span>
            </div>
        </div>
    )
};

class FeedNewsByCategories extends React.PureComponent {

    render() {
        const { description, values = [], showFilter = false } = this.props;
        return (
            <section {...bem()}>
                {
                    showFilter && <Filter/>
                }
                <div {...bem('title')}>
                    <div dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
                <div className="row">
                    {
                        values.map((value, index) => {
                            return (
                                <Article key={index} {...value}/>
                            )
                        })
                    }
                </div>
            </section>
        );
    }
}

export default FeedNewsByCategories;
