import React, { PureComponent } from 'react';
import { translate } from "react-i18next";


class Page404 extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <section className="ff-main-section ff-404">
                <div className="container">
                    <div className="ff-404__container">
                        <h1>404</h1>
                        <h3>{t(`ff-shop:client.404.description`)}</h3>
                    </div>
                </div>
            </section>
        );
    }
}

export default translate(['ff-shop'], { wait: true })(Page404);
