import React from 'react';
import FontAwesome from 'react-fontawesome';
import BEMHelper from 'react-bem-helper';
import { Link } from "react-router-dom";

const bem = new BEMHelper({
    prefix: 'ff-admin-entities-',
    name: 'user'
});

class Page extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { id, state = 0, isSelected, name, last_name, email, last_access, phone, type } = this.props;
        const type_desc = type === 'ADMIN' ? 'Admin' : 'Sócio';
        return (
            <div {...bem(null, { selected: isSelected }, {
                'panel': true,
                'panel-primary': !isSelected
            })}>

                <div className="panel-heading">
                    {
                        isSelected && <div className="select-icon">
                            <FontAwesome name="check"/>
                        </div>
                    }
                    <div className="panel-heading__container">
                        <h4>{name} {last_name} ({type_desc})</h4>
                    </div>
                </div>

                <div className="panel-body">
                    <div {...bem('container')}>
                        <div>
                            <span>Email:</span><label>{email}</label>
                        </div>
                        <div>
                            <span>Telefone:</span><label>{phone}</label>
                        </div>

                        {
                            last_access && <div>
                                <span>Ultimo acesso:</span><label>{last_access}</label>
                            </div>
                        }

                        <Link
                            className={`btn btn-default btn-small`}
                            to={`/admin/partners/${state}/${id}`}>Ver</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Page;
