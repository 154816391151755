import React, { PureComponent } from 'react';
import moment from 'moment';

export default class SectionTypeDate extends PureComponent {

    render() {
        const { date } = this.props;
        const mEnd_date = moment(new Date(date)).format('DD MMMM YYYY');
        return (
            <label>
                {mEnd_date}
            </label>
        );
    }
}
