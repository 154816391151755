import React from 'react';
import { HeaderForm } from "../../forms";
import CrudHeaderFooter from "../../components/crudHeaderFooter";
import NavigationExample from "../../../client/components/Navigation/exemplo";
import LinkedCrud from "../../components/linked-crud";
import PageEntity from "../../components/entities/page";
import GroupOfPagesEntity from "../../components/entities/group-of-page";
import PageCategory from "../../forms/page";
import GroupOfPagesForm from "../../forms/group-of-pages";
import CRUDEntity from "../../components/crud";

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch, isFetching = false } = this.props;
        if (isFetching) {
            return <div>Loading</div>;
        }
        const defaultValues = {};
        return (

            <section className="my-ff-admin-section ff-admin-section container-fluid">
                <CrudHeaderFooter
                    title={"Header"}
                    dispatch={dispatch}
                    entity="header"
                    defaultValues={{}}
                    entityView={NavigationExample}
                    form={HeaderForm}/>

                <LinkedCrud
                    title={"Paginas"}
                    dispatch={dispatch}
                    entity="pages"
                    defaultValues={defaultValues}
                    entityView={PageEntity}
                    form={PageCategory}/>

                <CRUDEntity
                    title={"Grupo de paginas"}
                    dispatch={dispatch}
                    entity="group-of-pages"
                    defaultValues={defaultValues}
                    entityView={GroupOfPagesEntity}
                    form={GroupOfPagesForm}/>

            </section>

        );
    }
}

export default Page;
