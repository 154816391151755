import axios from 'axios'
import store from './store'
import * as actions from './store/actions'

let token = document.head.querySelector('meta[name="csrf-token"]');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;

axios.interceptors.response.use(
    response => response,
    (error) => {
        const statusCode = error.response.status;

        if (statusCode === 401 && window.location.href.includes("login")) {
            return Promise.reject(error);
        }

        if (statusCode === 401 && window.location.href.includes("reset-password")) {
            return Promise.reject("nothing");
        }

        if (statusCode === 401) {
            store.dispatch(actions.authLogout());
        } else if (statusCode === 404) {
            //fixme: implement this logic in server side
            window.location.href = '/#/404';
        }
        return Promise.reject(error);
    }
);
export default axios;
