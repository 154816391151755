import React from "react";
import BEMHelper from 'react-bem-helper';
import SectionTypeDate from "../date";
import Welcome from "../../Welcome";
import { Banner, FeedNews, FeedNews2, FeedNewsByCategories } from "../../index";

const bemNews = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-news'
});
const bemNotice = new BEMHelper({
    prefix: 'ff-',
    name: 'cli-notice'
});

const Notice = ({ show_divider = true, entity, title, address, pdf, date, description, image, index, id }) => {
    const text_align = index % 2 === 0 ? 'right' : 'left';
    const hasMore = false;
    return (
        <div {...bemNotice()}>
            <h3>{title}</h3>
            <div {...bemNotice('local')}>
                <label>{address}</label>
                <SectionTypeDate date={date}/>
                {
                    pdf && <a target="_blank" href={"/files/" + pdf}>Ata</a>
                }
                {
                    hasMore &&
                    <a href={entity === 'news' ? '#/pages/detail/news/' + id : '#/pages/detail/jornadas/' + id}>Ver</a>
                }
            </div>
            <Welcome value={description} image={image} text_align={text_align}/>
            {show_divider && <hr/>}
        </div>
    );
};

const List = ({ entity, render_type, description, values = [] }) => {
    const totalValues = values.length - 1;
    return (<div {...bemNews()}>
        <div
            {...bemNews('description')}
            dangerouslySetInnerHTML={{ __html: description }}/>
        {
            values.map((val, index) => {
                return <Notice
                    index={index}
                    show_divider={totalValues > index}
                    entity={entity} {...val}
                    key={index}/>
            })
        }
    </div>);
};

const NewsSection = (props) => {

    switch (props.render_type) {
        case 'List':
            console.log("List--> ", props);
            return <List {...props}/>;
            break;
        case 'Slider':
            return <Banner {...props}/>;
            break;
        case 'Cenas1':
            return <FeedNews {...props}/>;
            break;
        case 'Cenas2':
            return <FeedNews2 {...props}/>;
            break;
        case 'Cenas3':
            return <FeedNewsByCategories {...props}/>;
            break;
        default:
            return <div>Default</div>;
    }

};

export default NewsSection;
