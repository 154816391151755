import React, { Component } from 'react';

export default class Limit extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { section, has_limit, limit } = this.props;

        return (
            <div>
                <div className="input-row">
                    <input className="form-control" {...has_limit.input} type="checkbox"/>
                    {has_limit.meta.touched && has_limit.meta.error &&
                    <span className="error">{has_limit.meta.error}</span>}
                </div>
                {
                    has_limit.input.value === true && <div className="input-row">
                        <input className="form-control input-sm" {...limit.input} type="number"/>
                        {limit.meta.touched && limit.meta.error &&
                        <span className="error">{limit.meta.error}</span>}
                    </div>
                }
            </div>
        );
    }
}
