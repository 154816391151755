const getImage = (sectionData) => {

    let image = '';
    if (sectionData.isNotSaved) {
        if (sectionData.type === 'Product') {
            image = sectionData.main_image;
        } else if (sectionData.type === 'Category') {
            image = sectionData.image;
        } else {
            image = sectionData.image;
        }
    } else {
        if (sectionData.type === 'Product') {
            image = sectionData.product.main_image;
        } else if (sectionData.type === 'Category') {
            image = sectionData.category.image;
        } else {
            image = sectionData.extra.image;
        }
    }

    return image;
};

const getUrl = (sectionData) => {

    let url = null;

    if (sectionData.type === 'Product') {
        url = `/products/${sectionData.product.id}`;
    } else if (sectionData.type === 'Category') {
        url = `/categories/${sectionData.category.id}`;
    } else {
        url = null;
    }

    return url;
};
const getPrice = (sectionData) => {
    let price = null;
    if (sectionData.type === 'Product') {
        price = sectionData.product.base_price;
    } else if (sectionData.type === 'Category') {
        price = null;
    } else {
        price = null;
    }
    return price;
};

const CalculateGrid = (listLength) => {
    const max = 3;
    const grid = 12;
    return listLength > max ? 4 : grid / listLength;
};

export { CalculateGrid, getImage, getUrl, getPrice };