import React from 'react'
import { Field } from 'redux-form';
import * as Validators from '../../../../admin/forms/utils/validators';
import InputError from "../../../../common/components/InputError";

const MyField = (props) => {
    const { icon, type = 'text', input, label, meta, metadata } = props;

    return (

        <div className="form-group">
            <div className="input-group">
                <span className="input-group-addon">
                    <i className={icon}></i>
                </span>
                <input className="form-control input-sm" {...input} type={type} placeholder={label}/>
            </div>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const Form = (props) => {
    const { isLoading, success } = props;
    return (
        <div className="ff-form_login container smpor-forgot-password smpor-forgot-password__form">
            <h3 className="ff-form_authTitle">Recuperar palavra passe.</h3>


            <div className="row ff-form_row-sm-offset-3 ff-form_loginOr">
                <div className="col-xs-12 col-sm-6">
                    <hr className="ff-form_hrOr"/>
                </div>
            </div>

            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    <label>Será
                        enviado um email com o próximo passo.</label>
                </div>
            </div>

            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    <Field
                        validate={[Validators.required, Validators.email]}
                        label="E-mail"
                        icon="fa fa-user"
                        type="text"
                        name='email'
                        placeholder='E-mail'
                        component={MyField}/>
                </div>
            </div>

            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    {
                        success && <label>Foi enviado um email para continuar o processo.</label>
                    }
                </div>
            </div>
            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6 text-right">
                    <button
                        disabled={isLoading || success}
                        className="btn btn-sm btn-primary"
                        type="submit">Repor
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Form;
