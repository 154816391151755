import Http from '../Http';
import { toast } from 'react-toastify';

export function createEntity(entity, data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.post(`/api/${entity}`, data)
                .then((res) => {
                    toast.success("Gravado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}

export function deleteEntity(entity, id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.delete(`/api/${entity}/${id}`)
                .then((res) => {
                    toast.success("Eliminado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}

export function editEntity(entity, updateProduct) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.put(`/api/${entity}/${updateProduct.id}`, updateProduct)
                .then((res) => {
                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}

export function getListEntities(entity) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/api/${entity}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}

export function getEntityById(entity, productId) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(`/api/${entity}/${productId}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch(err => {
                    const statusCode = err.response.status;
                    return reject({ statusCode });
                })
        })
    )
}
