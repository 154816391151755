import { connect } from 'react-redux';
import Page from './Page';
import * as ServicePages from '../../../services/Pages';

const mapStateToProps = (state) => {
    return { Pages: state[ServicePages.REDUCER_NAME] }
};

const mapActions = (dispatch) => {
    return { dispatch }
};

export default connect(mapStateToProps, mapActions)(Page)
