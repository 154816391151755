import React, {Component} from 'react';
import Lightbox from 'react-images';

class Gallery extends Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    openLightbox(index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    gotoImage(index) {
        this.setState({
            currentImage: index,
        });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }

    renderGallery() {
        const {description, values = []} = this.props;
        let images = values;
        if (images.length === 0) return;

        const firstImage = images[0];
        const gallery = images.filter(i => {
            if (i != 0) {
                return true;
            } else {
                return false
            }
        }).map((obj, i) => {
            return (
                <a
                    href={`/images/gallery/originals/${obj.image}`}
                    key={i}
                    onClick={(e) => this.openLightbox(i + 1, e)}>
                    <img src={`/images/gallery/thumbnails/${obj.image}`}
                         className="ff-gallery__secondary-thumbnail"/>
                </a>
            );

        });

        return (
            <div className="ff-gallery">
                <div className="row">
                    <div className="col-xs-12">
                        {
                            description && <div dangerouslySetInnerHTML={{__html: description}}/>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <a
                            href={`/images/gallery/originals/${firstImage.image}`}
                            onClick={(e) => this.openLightbox(0, e)}>
                            <img src={`/images/gallery/originals/${firstImage.image}`}
                                 className="ff-gallery__primary-thumbnail"/>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 ff-gallery__secondaries">
                        {gallery}
                    </div>
                </div>
            </div>
        );
    }

    getImages(images) {
        images = images.map((imageObj) => {
            return imageObj.image;
        });

        return images.map((img, i) => {
            const id = i + 1;

            let original = `/images/gallery/originals/${img}`;
            let thumbnail = `/images/gallery/thumbnails/${img}`;
            if (i === 0) {
                thumbnail = `/images/gallery/originals/${img}`;
            }

            return {
                id,
                position: i,
                isMain: i === 0,
                src: original,
                thumbnail: thumbnail,
                caption: `Foto ${id}`,
                isVisible: i < 15
            };
        });

    }

    render() {
        const {values, showThumbnails, theme} = this.props;
        const {currentImage, lightboxIsOpen} = this.state;
        let images = this.getImages(values);

        console.log("-------------------->>>>", images);
        return (
            <div className="section">
                {this.renderGallery()}
                <Lightbox
                    currentImage={currentImage}
                    images={images}
                    isOpen={lightboxIsOpen}
                    showThumbnails={showThumbnails}
                    theme={theme}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                    onClose={this.closeLightbox}/>
            </div>
        );
    }
}

export default Gallery;
