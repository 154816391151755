import React from 'react'
import PropTypes from 'prop-types'
import { Validator } from 'ree-validate'
import { SubmissionError } from 'redux-form';
import Form from './Form'
import { PartnerService } from '../../../../services'

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false
        };
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values) {
        this.setState({
            isLoading: true
        });
        return this.props.dispatch(PartnerService.put(values))
            .then((result) => {
                this.setState({
                    isLoading: false,
                    isSuccess: true,
                });
            })
            .catch(({ error, statusCode }) => {
                this.setState({
                    isLoading: false,
                    isSuccess: false,
                });
                throw new SubmissionError({
                    password: statusCode,
                    _error: 'Login failed!'
                })
            });

    }

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, isSuccess } = this.state;

        return (
            <section className="ff-main-section ff-main-section__login">
                <div className="contact">
                    <div className="container">
                        <div className="row">
                            <form className="ff-form_loginForm" onSubmit={handleSubmit(this.handlerSubmit)} noValidate>
                                <Form
                                    success={isSuccess}
                                    isLoading={isLoading}/>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Page.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Page;
