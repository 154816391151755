import {
    AdminHeaderFooter,
    AdminJorneys,
    AdminNews,
    AdminPageDetail,
    AdminPages,
    AdminPartner,
    AdminPartnerDetail,
    AdminUserContacts,
    AdminUserContactsDetail,
    AdminUsers
} from '../admin/pages/index'
import { Article, EntityInfo, Home, Page404, PageInfo } from '../client/containers/index'
import { ForgotPassword, Login, MyAccount, ResetPassword } from "../client/containers/account";

const routes = [
    {
        path: '/pages/:pageId',
        exact: true,
        auth: false,
        component: PageInfo
    },
    {
        path: '/pages/detail/:entity/:entityId',
        exact: true,
        auth: false,
        component: EntityInfo
    },
    {
        path: '/home',
        exact: true,
        auth: false,
        component: Home
    },
    {
        path: '/contactos',
        exact: true,
        auth: false,
        component: Article
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Login
    },
    {
        path: '/forgot-password',
        exact: true,
        auth: false,
        component: ForgotPassword
    },
    {
        path: '/reset-password/:token/:email',
        exact: true,
        auth: false,
        component: ResetPassword
    },
    {
        path: '/my-account',
        exact: true,
        auth: false,
        component: MyAccount
    },
    {
        path: '404',
        exact: true,
        auth: false,
        component: Page404
    },
    {
        path: '',
        exact: true,
        auth: false,
        component: PageInfo
    }
];

const adminRoutes = [
    {
        path: '/admin/header-footer',
        exact: true,
        component: AdminHeaderFooter
    },
    {
        path: '/admin/pages',
        exact: true,
        component: AdminPages
    },
    {
        path: '/admin/pages/:pageId',
        exact: true,
        component: AdminPageDetail
    },
    {
        path: '/admin/news',
        exact: true,
        component: AdminNews
    },
    {
        path: '/admin/jorneys',
        exact: true,
        component: AdminJorneys
    },
    {
        path: '/admin/users',
        exact: true,
        component: AdminUsers
    }, {
        path: '/admin/user-contacts',
        exact: true,
        component: AdminUserContacts
    },
    {
        path: '/admin/user-contacts/:contactId',
        exact: true,
        component: AdminUserContactsDetail
    }, {
        path: '/admin/partners/:state',
        exact: true,
        component: AdminPartner
    },
    {
        path: '/admin/partners/:state/:partnerId',
        exact: true,
        component: AdminPartnerDetail
    }
];

export { routes, adminRoutes };
