import * as ActionTypes from '../action-types';

const initialState = {
    data: { products: [] },
    fetch: true
};

const OrderDetail = (state = initialState, { type, data }) => {
    switch (type) {
        case ActionTypes.GET_ORDER_DETAIL:
            return get(state, data);
        default:
            return state;
    }
};

const get = (state, orderDetail) => {
    state = Object.assign({}, state, { data: orderDetail, fetch: false });
    return state;
};

export default OrderDetail;