import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Field, FieldArray } from 'redux-form';
import Image from '../image/index';
import ListOfImages from '../list-of-images/index';

const bem = new BEMHelper({
    prefix: 'ff-admin-input-',
    name: 'gallery'
});

class Gallery extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div {...bem()}>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <Field
                            isClear={true}
                            onlyUrl={true}
                            label="Imagem principal"
                            name="main_image"
                            component={Image}/>
                    </div>

                    <div className="col-xs-12 col-md-6">
                        <FieldArray
                            name={`gallery`}
                            component={ListOfImages}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;