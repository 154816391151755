import React from 'react'
import { Field } from 'redux-form';
import * as Validators from '../../../../admin/forms/utils/validators';
import InputError from "../../../../common/components/InputError";
import InputImage from "../../../../admin/forms/inputs/avatar";

const MyField = (props) => {
    const { disable = false, icon, type = 'text', input, label, meta, metadata } = props;

    return (

        <div className="form-group">
            <label>{label}</label>
            <div className="input-group">
                <span className="input-group-addon">
                    <i className={icon}></i>
                </span>
                <input
                    className="form-control input-sm"
                    {...input}
                    disabled={disable}
                    type={type}
                    placeholder={label}/>
            </div>
            <InputError {...meta} metadata={metadata}/>
        </div>
    );
};

const Form = (props) => {
    const { isLoading, success } = props;
    return (
        <div className="ff-form_login container smpor-forgot-password smpor-forgot-password__form">

            <div className="row">
                <div className="col-xs-4 col-sm-3">
                    <Field
                        label="A sua foto"
                        name='avatar'
                        component={InputImage}/>
                </div>

                <div className="col-xs-8 col-sm-9">
                    <Field
                        validate={[Validators.required]}
                        label="Nome"
                        icon="fa fa-user"
                        type="text"
                        name='name'
                        placeholder='E-mail'
                        component={MyField}/>
                    <Field
                        validate={[Validators.required]}
                        label="Apelido"
                        icon="fa fa-user"
                        type="text"
                        name='last_name'
                        placeholder='E-mail'
                        component={MyField}/>
                    <Field
                        validate={[Validators.required, Validators.email]}
                        label="E-mail"
                        disable={true}
                        icon="fa fa-user"
                        type="text"
                        name='email'
                        placeholder='E-mail'
                        component={MyField}/>
                    <Field
                        validate={[Validators.required]}
                        label="Contato telefonico"
                        icon="fa fa-user"
                        type="text"
                        name='phone'
                        placeholder='Contato telefonico'
                        component={MyField}/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-sm-12 text-right">
                    <button
                        className="btn btn-sm btn-primary"
                        type="submit">Gravar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Form;
