import React from 'react';
import Base from '../Base';
import Http from "../../Http";

const ENTITY = 'user-contact';
export const REDUCER_NAME = "UserContact";

const initialState = { isFetching: true, isSingleObject: false, values: [], formValues: {}, valuesById: {} };

class Pages extends Base {
    constructor(...props) {
        super(...props);
    }

    reducer(state = this.initialState, action = {}) {
        const withInitialState = {
            ...state,
            initialState: this.initialState
        };
        switch (action.type) {
            case this.GET: {
                const defaultValues = state.isSingleObject ? {} : [];
                return {
                    ...withInitialState,
                    values: action.data || defaultValues,
                    isFetching: false,
                    isError: false
                };
            }
            case this.GET_BY_ID: {
                return {
                    ...withInitialState,
                    valuesById: action.data,
                    isFetching: false,
                    isError: false
                };
            }
            case this.PUT: {
                const values = state.isSingleObject ? action.data : state.values.concat(action.data || []);

                return {
                    ...withInitialState,
                    isError: false,
                    values
                };
            }
            case this.CREATE: {
                return {
                    ...withInitialState,
                    isError: false,
                    formValues: {}
                };
            }
            case this.FETCH: {
                return {
                    ...withInitialState,
                    isFetching: true,
                    isError: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            case this.RESET: {
                return {
                    ...withInitialState,
                    isFetching: false,
                    isError: false,
                    formValues: {}
                };
            }
            case this.DELETE: {
                return {
                    ...withInitialState,
                    isError: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            case this.ERROR: {
                return {
                    ...withInitialState,
                    isError: true,
                    isFetching: false,
                    values: state.isSingleObject ? {} : []
                };
            }
            default: {
                return withInitialState;
            }
        }
    }

    postAsync(url, data) {

        return (dispatch) => (
            new Promise((resolve, reject) => {
                Http.post(url, data)
                    .then((res) => {
                        dispatch(this.post(res.data));
                        dispatch(this.reset());
                        return resolve(res.data);
                    })
                    .catch(err => {
                        const statusCode = err.response.status;
                        return reject({ statusCode });
                    })
            })
        );
    }
}

const news = new Pages(ENTITY, initialState, true);

export const reducer = news.reducer;

export const getAll = (id, params = {}) => {
    return news.getAsync(`api/${ENTITY}`, params);
};

export const getById = (id, params = {}) => {
    return news.getByIdAsync(`api/${ENTITY}/${id}`, params);
};

export const post = (data) => {
    return news.postAsync(`api/${ENTITY}`, data);
};