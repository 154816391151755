import React from 'react';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux'
import * as actions from '../../../store/actions'

class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(event) {
        event.preventDefault();
        this.props.dispatch(actions.authLogout());
    }

    componentWillMount() {
        this.setState({ expanded: false })
    }

    expand() {
        this.setState({ expanded: !this.state.expanded });
    }

    min() {
        this.setState({ expanded: false });
    }

    render() {
        const { expanded = false } = this.state;
        const { isAuthenticated = false, values = [] } = this.props;
        return (
            <header className="navbar">

                <div className="main_menu_bg">
                    <div>
                        <div className="container-fluid">
                            <Navbar expanded={expanded} className="navbar">
                                <Navbar.Header>
                                    <Navbar.Toggle onClick={this.expand.bind(this)}>
                                        Menu <i className="fa fa-bars"></i>
                                    </Navbar.Toggle>
                                </Navbar.Header>
                                <div className="logo"></div>
                                <Navbar.Collapse>
                                    <Nav>
                                        {
                                            values.map((value, index) => {
                                                try {

                                                    if (value.type === 'Page') {
                                                        return (
                                                            <NavItem
                                                                key={index}
                                                                className="text-uppercase"
                                                                onClick={this.min.bind(this)}
                                                                href={`#/pages/${value.url_code}`}>{value.name}</NavItem>
                                                        )
                                                    } else if (value.type === 'GroupOfPages') {
                                                        return (
                                                            <NavDropdown
                                                                key={index}
                                                                eventKey={`3.${index}`}
                                                                title={value.name}
                                                                id="basic-nav-dropdown">
                                                                {
                                                                    value.group_of_pages[0].pages.map(({page}, index1) => {

                                                                        return (
                                                                            <MenuItem
                                                                                key={index1}
                                                                                onClick={this.min.bind(this)}
                                                                                href={`#/categories/${page.id}`}
                                                                                eventKey={`3.${index}.${index1}`}>{page.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </NavDropdown>
                                                        )
                                                    } else {
                                                        return (
                                                            <NavItem
                                                                key={index}
                                                                className="text-uppercase"
                                                                onClick={this.min.bind(this)}
                                                                href={`#/categories/${value.id}`}>{value.name}</NavItem>
                                                        )
                                                    }
                                                } catch (e) {
                                                    console.error(e);
                                                }
                                            })
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

NavigationBar.defaultProps = {
    brand: 'brand'
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated
    }
};

export default connect(mapStateToProps)(NavigationBar)
