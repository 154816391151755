import React from 'react';
import SelectBox from 'react-select';
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'ff-',
    name: 'multi-select'
});

const SimpleMultiSelect = ({ filterHandler, options = [], fields = [], label }) => {
    const myLabel = label ? label : 'Selecione';
    return (
        <section {...bem()}>
            <SelectBox
                closeText="Fechar"
                clearText="Limpar seleção"
                multiple={true}
                value={fields}
                onChange={filterHandler}>
                {
                    options.map((opObj, index) => {
                        const label = `${opObj.desc}`;
                        return (<option key={index} value={opObj.id}>{label}</option>);
                    })
                }
            </SelectBox>
        </section>
    );
};

export default SimpleMultiSelect;



