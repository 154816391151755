import React from 'react';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import * as Validators from '../../../../admin/forms/utils/validators';
import InputError from "../../../../common/components/InputError";

const MyField = (props) => {
    const { validateMetaData = {}, icon, type = 'text', input, label, meta } = props;

    return (

        <div className="form-group">
            <div className="input-group">
                <span className="input-group-addon">
                    <i className={icon}></i>
                </span>
                <input className="form-control input-sm" {...input} type={type} placeholder={label}/>
            </div>
            <InputError {...meta} metadata={validateMetaData}/>
        </div>
    );
};

const Login = () => {
    const showSocialLogins = false;
    return (
        <div className="ff-form_login container">
            <h3 className="ff-form_authTitle">Login</h3>
            {
                showSocialLogins && <div className="row ff-form_row-sm-offset-3 ff-form_socialButtons">
                    <div className="col-xs-6 col-sm-3">
                        <a href="#" className="btn btn-sm btn-block ff-form_btn-facebook">
                            <FontAwesome name="facebook-square"/>
                            <span>Facebook</span>
                        </a>
                    </div>
                    <div className="col-xs-6 col-sm-3">
                        <a href="#" className="btn btn-sm btn-block ff-form_btn-google">
                            <FontAwesome name="google-plus-square"/>
                            <span>Google+</span>
                        </a>
                    </div>
                </div>
            }

            <div className="row ff-form_row-sm-offset-3 ff-form_loginOr">
                <div className="col-xs-12 col-sm-6">
                    <hr className="ff-form_hrOr"/>
                </div>
            </div>
            <div className="row modal-login-row ff-form_row-sm-offset-3">
                <div className="col-xs-12 col-sm-6">
                    <Field
                        validate={[Validators.required, Validators.email]}
                        label="E-mail"
                        icon="fa fa-user"
                        type="text"
                        name='email'
                        placeholder='E-mail'
                        component={MyField}/>

                    <Field
                        validate={[Validators.required, Validators.minLength6]}
                        validateMetaData={{ min: 6 }}
                        label="Palavra passe"
                        icon="fa fa-lock"
                        name='password'
                        placeholder='Palavra passe'
                        type='password'
                        component={MyField}/>

                    <button className="btn btn-sm btn-primary btn-block" type="submit">Login</button>

                </div>
            </div>
            <div className="row ff-form_row-sm-offset-3">
                <div className="col-xs-6 col-sm-3 ff-form_checkbox">
                    <label className="checkbox">
                        <Field
                            name="remember_me"
                            component="input"
                            type="checkbox"/>lembre-me
                    </label>
                </div>
                <div className="col-xs-6 col-sm-3">
                    <p className="ff-form_forgotPwd">
                        <Link to='/forgot-password'><b>Recuperar palavra passe.</b></Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
