import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ServicePage from '../../../services/Page';

const Info = () => {

    return (
        <div>
            A SAMPor tem como visão a melhoria contínua da qualidade em medicina aeronáutica e espacial praticada em
            Portugal. Na prossecução da sua missão cabe à SMAPor:
            <ol>
                <li>Promover reuniões de consenso em medicina aerospacial;</li>
                <li>Fomentar a investigação e a divulgação de conhecimentos, ideias, experiências e projetos em medicina
                    aerospacial;
                </li>
                <li>Estabelecer parcerias e contactos preferenciais com universidades e outras entidades formadoras,
                    administrações de saúde, indústria aeronáutica, indústria farmacêutica e outros organismos, públicos
                    ou
                    privados, e com associações congéneres, nacionais ou internacionais;
                </li>
                <li>Representar os seus associados em associações congéneres nacionais e supranacionais;</li>
                <li>Promover atividades de formação para profissionais médicos e não-médicos, tais como cursos,
                    estágios,
                    conferências, colóquios, seminários, congressos, encontros e exposições;
                </li>
                <li>Criar programas específicos de certificação que podem ter como objeto profissionais, consultas,
                    centros
                    aeromédicos, atividades de formação;
                </li>
                <li>Dar colaboração e entidades oficiais ou de interesse público;</li>
                <li>Prestar aos seus associados o apoio necessário para a defesa dos seus interesses, quando estes se
                    enquadrem no objeto desta Associação.
                </li>
            </ol>
        </div>
    )
};

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData() {
        //this.props.getPage('1');
    }

    componentWillMount() {
        //this.getData();
    }

    render() {
        // const { Page: { isFetching, values } } = this.props;

        return (
            <section className="container">
                <div>
                    <h3>Missão</h3>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Info/>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Page: state[ServicePage.REDUCER_NAME]
    }
};

const mapActions = (dispatch) => {
    return bindActionCreators(
        {
            getPage: ServicePage.get
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapActions)(Article)
