import React from 'react';
import Modal from '../../components/modal';
import SliderFormModal from "./SliderFormModal";
import {getImage} from "../../../Utils/GridUtil";
import InputError from "../../../common/components/InputError";

class RenderSlider extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.onClick = this.onClick.bind(this);
        this.successHandler = this.successHandler.bind(this);
    }

    onClick() {
        this.setState({isOpen: !this.state.isOpen});
    }

    successHandler(newValues) {
        this.setState({isOpen: !this.state.isOpen});
        const {fields} = this.props;

        newValues.map((val) => {
            fields.push(val);
        });
    }

    render() {
        const {fields, meta, metadata} = this.props;
        return (
            <div className="ff-adm-slider">

                <div className="row">
                    {
                        fields.map((section, index) => {
                            const sectionData = fields.get(index);

                            let img = getImage(sectionData);

                            return (
                                <div className="ff-adm-slider__item col-xs-6 col-md-3" key={index}>

                                    <div className="ff-adm-slider__item-container">
                                        <div className="actions">
                                            <button
                                                disabled={index === 0}
                                                className="btn btn-xs btn-default"
                                                type="button"
                                                title="Remover"
                                                onClick={() => fields.move(index, index - 1)}>
                                                <i className="glyphicon glyphicon-arrow-left"></i>
                                            </button>
                                            <button
                                                disabled={index === fields.length - 1}
                                                className="btn btn-xs btn-default"
                                                type="button"
                                                title="Remover"
                                                onClick={() => fields.move(index, index + 1)}>
                                                <i className="glyphicon glyphicon-arrow-right"></i>
                                            </button>
                                            <button
                                                className="btn btn-xs btn-default"
                                                type="button"
                                                title="Remove Member"
                                                onClick={() => fields.remove(index)}>
                                                <i className="glyphicon glyphicon-trash"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <h4 className="title">{sectionData.type}: {sectionData.name}</h4>
                                            <div className="ff-adm-slider__item-image">
                                                <img src={`/images/gallery/thumbnails/${img}`}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-xs-3 ff-adm-slider__empty-item">
                        <div className="ff-adm-slider__empty-item-container" onClick={this.onClick}>
                            <i className="glyphicon glyphicon-plus"></i>
                        </div>
                    </div>
                </div>
                <InputError type="field_array" {...meta} metadata={metadata}/>
                <Modal isOpen={this.state.isOpen}>
                    <SliderFormModal
                        close={this.onClick}
                        successHandler={this.successHandler}/>
                </Modal>
            </div>
        )
    }
}

export default RenderSlider;
