import React, { PureComponent } from 'react';
import Slider from 'react-animated-slider';
import SectionTypeDate from "../PageSections/date";

const Slide = ({ image, title, show, description, date, infoUser = null }) => {

    return (
        <div className="smapor-slider-conatiner">
            {
                show && <div className="logo">
                    <img src={'/images/static/logo.png'}/>
                </div>
            }

            {
                false && !show && <div className="logo logo--1">
                    <img src={'/images/static/logo.png'}/>
                </div>
            }

            <div className="inner">
                {
                    !show && <h1>{title}</h1>
                }
            </div>
            {
                infoUser && <section>
                    <img src={infoUser.image} alt={infoUser.user}/>
                    <span>Por: <strong>{infoUser.name}</strong></span>
                </section>
            }

        </div>
    );
};

class Brand extends PureComponent {

    render() {
        const { values } = this.props;
        return (
            <Slider
                button-disabled={false}
                className="slider-wrapper"
                autoplay={3000}>
                {
                    values.map(
                        (item, index) => {

                            const styles = {
                                background: `url('/images/gallery/originals/${item.image}') no-repeat center center`
                            };

                            return (
                                <div
                                    key={index} className="slider-content"
                                    style={styles}>
                                    <Slide {...item}/>
                                </div>
                            )
                        }
                    )
                }
            </Slider>
        );
    }
}

export default Brand;
