import React from 'react';
import Crud from '../../components/crudUsers';
import { UserForm } from '../../forms';
import { EntityUser } from '../../components/entities';

class Page extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { dispatch } = this.props;
        const defaultValues = {};
        return (
            <section className="ff-admin-section">
                <div className="container-fluid">
                    <Crud
                        showActions={true}
                        dispatch={dispatch}
                        entity="users"
                        defaultValues={defaultValues}
                        entityView={EntityUser}
                        form={UserForm}/>
                    <br/>
                </div>
            </section>
        );
    }
}

export default Page;
