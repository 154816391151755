import React from 'react'
import { Redirect, Route } from 'react-router'
import { connect } from 'react-redux'
import Main from '../Main'

const AdminRoute = ({ component: Component, isAuthenticated, isAdmin, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated && isAdmin ? (
            <Main>
                <Component {...props}/>
            </Main>
        ) : (
            <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }}/>
        )
    )}/>
);

const mapStateToProps = (state) => {
    return {
        isAuthenticated: true,
        isAdmin: true,
    };

    const x = {
        isAuthenticated: state.Auth.isAuthenticated,
            isAdmin: state.Auth.isAdmin,
    };
};

export default connect(mapStateToProps)(AdminRoute);
