import React, {PureComponent} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
//import 'react-datepicker/dist/react-datepicker.css';

const dateFormat = 'DD-MM-YYYY';
const dateFormatToServer = 'YYYY-MM-DD';

class MyDatePicker extends PureComponent {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const formattedDate = date && date.format(dateFormatToServer);
    const {input: {onChange}} = this.props;
    onChange(formattedDate);
  }

  render() {
    const {label, input: {value}, meta: {touched, error}} = this.props;
    const select = moment(value ? new Date(value) : new Date(), dateFormat);
    return (
      <div className="form-group">
        <label>{label}</label>
        <div>
          <DatePicker
            dateFormat={dateFormat}
            selected={select}
            className="form-control"
            onChange={this.handleChange}/>
          <div className="form__errors">
            {touched && error && <span>{error}</span>}
          </div>
        </div>
      </div>
    );
  }
}

MyDatePicker.propTypes = {};
MyDatePicker.defaultProps = {};

export default MyDatePicker;
