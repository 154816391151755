import React from 'react'
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'spor-',
    name: 'single-article'
});

class SingleArticle extends React.PureComponent {

    render() {

        return (
            <section {...bem()}>
                <div {...bem('title')}>
                    <h2>Descrição</h2>
                </div>

                <div {...bem('info')}>
                    <div {...bem('main-img')}></div>
                    <div {...bem('text')}>
                        <p>A Sociedade Médica Científica Aeroespacial- associação portuguesa (SMAPor) foi registada a 24
                            de
                            janeiro de 2017, tendo a sua sede em Lisboa, na Ordem dos Médicos (que apoiou esta
                            iniciativa).
                        </p>

                        <p>Conta, neste momento com XX sócios. Vários médicos aeronáuticos e operadores de treino
                            fisiológico da Força Aérea fazem parte, tantos dos corpos sociais, como das secções
                            especializadas da Sociedade.</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SingleArticle;
