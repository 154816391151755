import React from 'react'
import BEMHelper from 'react-bem-helper';

const bem = new BEMHelper({
    prefix: 'spor-',
    name: 'popular'
});

const Article = () => {
    return (
        <article {...bem('article')}>
            <div {...bem('article-img-container')}>
                Image
            </div>
            <div {...bem('article-info')}>
                <div {...bem('article-info-text')}>
                    <h3>Text text text</h3>
                </div>
                <div {...bem('article-info-footer')}>
                    <span>Pedro</span> -
                    <span>7.8.2018</span>
                </div>
            </div>
        </article>);
};

class Popular extends React.PureComponent {

    render() {

        return (
            <section {...bem()}>
                <div {...bem('title')}>
                    <h2>Title</h2>
                </div>

                <div {...bem('articles')}>
                    <Article/>
                    <Article/>
                    <Article/>
                    <Article/>
                    <Article/>
                </div>
            </section>
        );
    }
}

export default Popular;
