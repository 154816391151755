import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Page from './Page';

export const NAME = 'LoginForm';

const Form = reduxForm({
    form: NAME
})(Page);

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isAdmin: state.Auth.isAdmin,
        initialValues: {}
    }
};

export default connect(mapStateToProps)(Form)