import React from 'react'
import PropTypes from 'prop-types'
import {Validator} from 'ree-validate'
import {SubmissionError} from 'redux-form';
import Form from './Form'
import {AuthService} from '../../../../services'
import {Redirect} from "react-router";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            submited: false,
            success: false
        };
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(credentials) {
        this.setState({
            isLoading: true,
            submited: true
        });

        const data = {
            password: credentials.password,
            password_confirmation: credentials.repeat_password,
            token: this.props.match.params.token,
            email: this.props.match.params.email.replace("29gnmLTv686QsnV", "@")
        };

        return this.props.dispatch(AuthService.updatePassword(data))
            .then((result) => {
                this.setState({
                    isLoading: false,
                    success: true,
                });
            })
            .catch(({error, statusCode}) => {
                this.setState({
                    isLoading: false,
                    success: false,
                });
                throw new SubmissionError({
                    repeat_password: error,
                    _error: statusCode
                })
            });

    }

    render() {
        const {handleSubmit} = this.props;
        const {isLoading, success, submited} = this.state;

        if (submited && success) {
            return <Redirect to='/login' replace/>;
        }

        return (
            <section className="ff-main-section ff-main-section__login">
                <div className="contact">
                    <div className="container">
                        <div className="row">
                            <form className="ff-form_loginForm" onSubmit={handleSubmit(this.handlerSubmit)} noValidate>
                                <Form isLoading={isLoading}/>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Page.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Page;
