import React from 'react'
import { HashRouter as Router, Switch} from 'react-router-dom'
import { connect } from 'react-redux'

import { adminRoutes, routes } from './routes'
import PublicRoute from './Public'
import PrivateRoute from './Private'
import AdminRoute from './Admin'

const Routes = ({ auth: { isAuthenticated, isAdmin } }) => {
    return (
        <Router>
            <Switch>
                {
                    adminRoutes.map((route, i) => {
                        return <AdminRoute key={100 + i} {...route}/>
                    })
                }
                {
                    routes.map((route, i) => {
                        if (route.auth) {
                            return <PrivateRoute key={i} {...route}/>
                        } else {
                            return <PublicRoute key={i} {...route}/>
                        }
                    })
                }
            </Switch>
        </Router>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.Auth,
    }
};

export default connect(mapStateToProps)(Routes);