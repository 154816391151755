import React from 'react';
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import * as actions from '../../../store/actions'
import MyAccount from '../MyAccount'

class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = { redirect: false }
    }

    handleLogout(event) {
        event.preventDefault();
        this.props.dispatch(actions.authLogout());

    }

    render() {
        const { isAuthenticated } = this.props;

        if (this.state.redirect) {
            const to = '/pages/home';
            /*
            return (
                <Redirect to={to}/>
            )
            */
        }

        return (
            <div className="container-fluid topbar">
                <div className="container">
                    <div className="pull-right topbar__actions">
                        <div className="topbar__session-actions">
                            {
                                isAuthenticated ? <Link onClick={this.handleLogout} to='/logout'>logout</Link> :
                                    <Link to='/login'>login</Link>
                            }
                        </div>
                        {isAuthenticated && <MyAccount/>}
                    </div>
                </div>
            </div>
        );
    }
}

NavigationBar.defaultProps = {
    brand: 'brand'
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isAdmin: state.Auth.isAdmin,
    }
};

export default connect(mapStateToProps)(NavigationBar)
