import React, { PureComponent } from 'react';
import Modal from '../modal';

class CreateEntity extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.onClick = this.onClick.bind(this);
        this.successHandler = this.successHandler.bind(this);
    }

    onClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    successHandler() {
        this.props.addHandler();
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { action, form, entity, defaultValues } = this.props;
        const Form = form;
        return (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={this.onClick}>Novo
                </button>

                <Modal isOpen={this.state.isOpen}>
                    <Form
                        entity={entity}
                        action={action}
                        defaultValues={defaultValues}
                        close={this.onClick}
                        successHandler={this.successHandler}/>
                </Modal>
            </div>
        );
    }
}

export default CreateEntity;
