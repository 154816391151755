import React from 'react'
import { connect } from 'react-redux'
import CookieConsent from "react-cookie-consent";
import { Navigation } from './client/components';
import { orderBy } from "lodash";
import { AuthService, CRUDService } from './services';
import { ToastContainer } from 'react-toastify';
import "./common/translate/i18n";
import { Link } from "react-router-dom";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            header: [],
            isError: false
        };
        this.mapData = this.mapData.bind(this);
    }

    mapData(data) {
        const pages = data.pages.map((obj) => {
            obj['type'] = 'Page';
            obj.name = obj.page.name;
            obj.url_code = obj.page.url_code;
            obj.entity_id = obj.page.id;
            return obj;
        });

        const group_of_pages = data.header_group_of_pages.map((obj) => {
            obj['type'] = 'GroupOfPages';
            obj.name = obj.group_of_pages[0].description;
            obj.entity_id = obj.group_of_pages[0].id;
            return obj;
        });

        return orderBy([...pages, ...group_of_pages], ['index'], ['asc']);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const _self = this;
        dispatch(CRUDService.getEntityById('header', 1))
            .then((res) => {
                const data = _self.mapData(res);
                this.setState({
                    isLoading: false,
                    header: data,
                    isError: false
                });
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
                this.setState({
                    isLoading: false,
                    isError: true
                });
            });
    }

    render() {
        const { isAdmin, Auth, dispatch } = this.props;
        const { isLoading, header } = this.state;

        if (Auth.isRequest === false) {
            dispatch(AuthService.getUserInfo());
        }

        if (Auth.isLoading && isLoading) {
            return <div></div>
        }

        return (
            <div className={isAdmin ? 'ff-admin ff' : 'ff-client ff'}>
                <div id="wrap">
                    <CookieConsent
                        location="bottom"
                        buttonText="OK"
                        contentClasses={"smapor-cokies"}
                        cookieName="accepted_cookies"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={30}>
                        <p>Utilização de cookies: Ao continuar a sua navegação está a consentir a utilização de cookies
                            que possibilitam a apresentação de serviços e ofertas adaptadas aos seus interesses.</p>
                        <p>Pode alterar as suas definições de cookies a qualquer altura.</p>
                        <Link to={"cookies"}>Saiba mais aqui</Link>
                    </CookieConsent>
                    <Navigation isAdmin={isAdmin} values={header}/>
                    <div className="container-fluid">
                        {
                            isAdmin && <div className="container-fluid fadeIn animated">
                                {this.props.children}
                            </div>
                        }
                        {
                            !isAdmin && <div className="ff-container fadeIn animated">
                                {this.props.children}
                            </div>
                        }
                        <div id="push"></div>
                    </div>
                </div>
                <ToastContainer autoClose={4000} position="top-right"/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let isAdmin = state.Auth.isAdmin;

    if (typeof isAdmin === 'string') {
        isAdmin = isAdmin == 'true' ? true : false;
    }
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        Auth: state.Auth,
        isAdmin
    }
};

export default connect(mapStateToProps)(Main);
