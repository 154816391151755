import React from 'react';
import {Field, FieldArray, Fields, FormSection} from "redux-form";
import {Image, RichText, Selection, Text} from "../../forms/inputs";
import RenderTextCols from "./RenderTextCols";
import RenderSlider from "./Slider";
import * as Validators from './../../forms/utils/validators'
import {
    CLIENT_TYPE_FEATURED,
    CLIENT_TYPE_FORM,
    CLIENT_TYPE_GRID,
    CLIENT_TYPE_IMAGE,
    CLIENT_TYPE_NEWS_EVENTS,
    CLIENT_TYPE_SEPARATOR,
    CLIENT_TYPE_TEXT,
    CLIENT_TYPE_TEXT_IMAGE,
    CLIENT_TYPE_VIDEO
} from "../../../Utils/Consts"
import Limit from "../../forms/inputs/limit";
import ListOfImages from "../../forms/inputs/list-of-images";
import Pdf from "../../forms/inputs/pdf";

/**
 * Get component by type.
 * @param type of component
 * @returns {*} Component module
 */
const GetInput = (pageInfo, section, type, loading = false) => {

    switch (type) {
        case CLIENT_TYPE_TEXT:
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div>
                            <label htmlFor="employed">Só para publicitar? </label>
                            <Field
                                name={`${section}.is_publicity`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <div>
                            <label htmlFor="employed">A toda a lagura do ecrã? </label>
                            <Field
                                name={`${section}.is_fullscreen`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <div style={{height: "426px"}}>
                            {
                                loading && <Field
                                    metadata={{min: 5, max: 10000}}
                                    validate={[Validators.minLength5, Validators.maxMediumRichText]}
                                    label="Titulo"
                                    isClear={true}
                                    onlyUrl={true}
                                    loading={loading}
                                    name={`${section}.value`}
                                    component={RichText}/>
                            }
                        </div>

                        <FieldArray
                            metadata={{min: 0, max: 15}}
                            validate={[Validators.maxLength15]}
                            name={`${section}.cols`}
                            component={RenderTextCols}/>

                        <Field
                            label="Documento"
                            entity="pages"
                            name={`${section}.pdf`}
                            entityId={pageInfo.id}
                            component={Pdf}/>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_IMAGE:
            return (
                <div className="row">
                    <div className="col-xs-8">
                        <div>
                            <label htmlFor="employed">Só para publicitar? </label>
                            <Field
                                name={`${section}.is_publicity`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <div>
                            <label htmlFor="employed">A toda a lagura do ecrã? </label>
                            <Field
                                name={`${section}.is_fullscreen`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>

                        <div>
                            <label htmlFor="employed">Logo tipo? </label>
                            <Field
                                name={`${section}.is_banner`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <Field
                            metadata={{min: 5, max: 150}}
                            validate={[Validators.required, Validators.minLength5, Validators.maxLength150]}
                            isClear={true}
                            onlyUrl={true}
                            name={`${section}.value`}
                            component={Image}/>
                    </div>
                </div>
            );
        case CLIENT_TYPE_VIDEO:
            return (
                <div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div>
                                <label htmlFor="employed">Começar a reproduzir? </label>
                                <Field
                                    name={`${section}.playing`}
                                    id="playing"
                                    component="input"
                                    type="checkbox"/>
                            </div>
                            <br/>
                            <Field
                                metadata={{min: 5, max: 10000}}
                                validate={[Validators.required, Validators.minLength5, Validators.maxMediumRichText]}
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.text`}
                                component={RichText}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                metadata={{min: 50, max: 1000}}
                                validate={[Validators.required, Validators.minLength5, Validators.maxLength1000]}
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.video_url`}
                                component={Text}/>
                        </div>
                    </div>
                </div>
            );
        case CLIENT_TYPE_FEATURED:
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            metadata={{min: 5, max: 250}}
                            validate={[Validators.minLength5, Validators.maxLength250]}
                            label="Titulo"
                            isClear={true}
                            onlyUrl={true}
                            name={`${section}.value`}
                            component={RichText}/>

                        <div>
                            <label htmlFor="employed">Só para publicitar? </label>
                            <Field
                                name={`${section}.is_publicity`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>

                        <div>
                            <label htmlFor="employed">A toda a lagura do ecrã? </label>
                            <Field
                                name={`${section}.is_fullscreen`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>

                        <FieldArray
                            metadata={{min: 5, max: 15}}
                            validate={[Validators.fieldArrayRequired, Validators.minLength5, Validators.maxLength15, Validators.fieldArrayUnique]}
                            name={`${section}.values`}
                            component={RenderSlider}/>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_TEXT_IMAGE:
            return (
                <div className="row">
                    <div className="col-xs-9">
                        <div className="col-xs-12">
                            <label htmlFor="employed">Só para publicitar? </label>
                            <Field
                                name={`${section}.is_publicity`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <div className="col-xs-12">
                            <label htmlFor="employed">A toda a lagura do ecrã? </label>
                            <Field
                                name={`${section}.is_fullscreen`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>

                        <div className="col-xs-12">
                            <label>Alinhamento do texto: </label>
                            <Field
                                validate={[Validators.required]}
                                name={`${section}.text_align`}
                                myOptions={[
                                    {
                                        value: 'left',
                                        label: 'Direita'
                                    },
                                    {
                                        value: 'right',
                                        label: 'Esquerda'
                                    }]}
                                component={Selection}>
                            </Field>
                        </div>
                    </div>
                    <div className="col-xs-9">
                        <div className="col-xs-12">
                            <Field
                                metadata={{min: 50, max: 1000}}
                                validate={[Validators.required, Validators.minLengthRich50, Validators.maxMediumRichText]}
                                label="Texto"
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.value`}
                                component={RichText}/>
                        </div>
                    </div>
                    <div className="col-xs-3">
                        <div className="col-xs-12">
                            <Field
                                validate={[Validators.required]}
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.image`}
                                component={Image}/>
                        </div>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_NEWS_EVENTS:
            return (
                <div>
                    <div className="row">
                        <div className="col-xs-2">
                            <label>Render: </label>
                            <Field
                                validate={[Validators.required]}
                                name={`${section}.render_type`}
                                component="select">
                                <option/>
                                <option value="List">Lista</option>
                                <option value="Slider">Slider</option>
                                <option value="Cenas1">Destaques: 2 grandes 4 pequenos</option>
                                <option value="Cenas2">Destaques: 3 pequenos 1 grande</option>
                                <option value="Cenas3">Destaques: Lista 2 colunas</option>
                            </Field>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                metadata={{min: 5, max: 1000}}
                                validate={[Validators.required, Validators.minLength5, Validators.maxMediumRichText]}
                                label="Texto"
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.description`}
                                component={RichText}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-2">
                            <label>Entidade: </label>
                            <Field
                                validate={[Validators.required]}
                                name={`${section}.entity`}
                                component="select">
                                <option/>
                                <option value="news">Noticias e Eventos</option>
                                <option value="jorneys">Jornadas</option>
                            </Field>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-2">
                            <label>Ordem: </label>
                            <Field
                                validate={[Validators.required]}
                                name={`${section}.order`}
                                component="select">
                                <option/>
                                <option value="asc">Antigos para os mais recentes</option>
                                <option value="desc">Recentes para os mais antigos</option>
                            </Field>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-2">
                            <label>Limit: </label>
                            <FormSection name={section}>
                                <Fields
                                    parent={section}
                                    names={[`has_limit`, `limit`]}
                                    component={Limit}/>
                            </FormSection>
                        </div>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_FORM:
            return (
                <div>
                    <div className="row">
                        <div className="col-xs-2">
                            <label>Formulario: </label>
                            <Field
                                validate={[Validators.required]}
                                name={`${section}.render_type`}
                                component="select">
                                <option/>
                                <option value="Contact">Contactos</option>
                                <option value="Partner">Socios</option>
                            </Field>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                metadata={{min: 5, max: 1000}}
                                validate={[Validators.required, Validators.minLength5, Validators.maxMediumRichText]}
                                label="Texto"
                                isClear={true}
                                onlyUrl={true}
                                name={`${section}.description`}
                                component={RichText}/>
                        </div>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_GRID:
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            metadata={{min: 5, max: 10000}}
                            validate={[Validators.minLength5]}
                            label="Descrição"
                            isClear={true}
                            onlyUrl={true}
                            name={`${section}.description`}
                            component={RichText}/>
                        <FieldArray
                            metadata={{min: 5, max: 12}}
                            validate={[Validators.fieldArrayRequired, Validators.minLength5, Validators.maxLength12]}
                            name={`${section}.values`}
                            component={ListOfImages}/>
                    </div>
                </div>
            );
            break;
        case CLIENT_TYPE_SEPARATOR:
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <div>
                            <label htmlFor="employed">Só para publicitar? </label>
                            <Field
                                name={`${section}.is_publicity`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <div>
                            <label htmlFor="employed">A toda a lagura do ecrã? </label>
                            <Field
                                name={`${section}.is_fullscreen`}
                                id="employed"
                                component="input"
                                type="checkbox"/>
                        </div>
                        <hr/>
                    </div>
                </div>
            );
            break;
        default:
            return (
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            metadata={{min: 5, max: 150}}
                            validate={[Validators.required, Validators.minLength5, Validators.maxLength150]}
                            name={`${section}.value`}
                            component={RichText}/>
                    </div>
                </div>
            );
    }
};

export default GetInput;
