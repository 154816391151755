import React from 'react';
import UserContact from './show';
import { Link } from "react-router-dom";

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    getData() {
        const { match: { params: { contactId } } } = this.props;
        this.props.getById(contactId);
    }

    componentWillMount() {
        this.getData();
    }

    render() {
        const { data: { isFetching, valuesById } } = this.props;

        if (isFetching) {
            return <div>Loading</div>;
        }

        return (
            <section className="ff-admin-section ff-adm-page-detail container">
                <div className="row">
                    <div className="col-xs-3">
                        <Link
                            className={`btn btn-small btn-default`}
                            to={`/admin/user-contacts`}>VOLTAR</Link>
                    </div>
                </div>
                <UserContact {...valuesById}/>
            </section>
        );
    }
}

export default Page;