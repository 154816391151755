import React, { PureComponent } from 'react';
import Modal from '../modal';

class CreateEntity extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.onClick = this.onClick.bind(this);
        this.successHandler = this.successHandler.bind(this);
    }

    onClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    successHandler() {
        this.props.addHandler();
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { list = {}, selectedIds = [], entity, form, defaultEvent = true } = this.props;
        const Form = form;
        const defaultValues = defaultEvent ? list[selectedIds[0]] : list;
        return (
            <div>
                <button
                    disabled={selectedIds.length === 0 || selectedIds.length > 1}
                    className="btn btn-primary"
                    onClick={this.onClick}>Editar
                </button>

                <Modal isOpen={this.state.isOpen}>
                    <Form
                        entity={entity}
                        defaultValues={defaultValues}
                        close={this.onClick}
                        successHandler={this.successHandler}/>
                </Modal>
            </div>
        );
    }
}

export default CreateEntity;
