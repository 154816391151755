import React from 'react'
import { FieldArray } from 'redux-form'
import SliderInput from './Slider'
import { CRUDService } from './../../../services'
import * as Validators from "../utils/validators";

export const required = value => (value ? undefined : 'Required');

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.handlerSubmit = this.handlerSubmit.bind(this);
    }

    handlerSubmit(values = {}) {
        const { entity, dispatch } = this.props;
        const successHandler = this.props.successHandler;
        values.id = 1;
        return dispatch(CRUDService.editEntity(entity, values))
            .then((res) => {
                this.setState({
                    isLoading: false
                });
                successHandler();
            })
            .catch(({ error, statusCode }) => {
                const responseError = {
                    isError: true,
                    code: statusCode,
                    text: error
                };
            })
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, close } = this.props;

        return (
            <form className="ff-admin-form" onSubmit={handleSubmit(this.handlerSubmit.bind(this))}>
                <div className="ff-admin-form__header">
                    <h2>Producto</h2>
                </div>
                <div className="ff-admin-form__content">
                    <div className="row">
                        <div className="col-md-12">
                            <FieldArray
                                validate={[Validators.fieldArrayRequired]}
                                name={`values`}
                                component={SliderInput}/>
                        </div>
                    </div>
                </div>

                <div className="ff-admin-form__footer">
                    <hr/>
                    <div className="ff-admin-form__footer-actions pull-left">
                        <button
                            type="button"
                            className="btn btn-primary btn-xl"
                            onClick={close}>Fechar
                        </button>
                        <button
                            type="button"
                            disabled={pristine || submitting}
                            className="btn btn-primary btn-xl"
                            onClick={reset}>Limpar
                        </button>
                    </div>
                    <div className="ff-admin-form__footer-actions pull-right">
                        <button
                            type="submit"
                            disabled={pristine || submitting}
                            className="btn  btn-primary btn-xl">Gravar
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default Page;